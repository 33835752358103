import React from "react";
import {TreeView} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import {getNodeOrNull, getNodesById, getNodesIfPresent} from "../../selectors/graphSelectors";
import {putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import ExecutionTOCItem from "./ExecutionTOCItem";
import {strings} from "../components/SopLocalizedStrings";
import { NODE_IDS } from "../../reducers/graphReducer";

class ExecutionTOC extends React.Component {
    treeContainerRef = React.createRef();
    parentElemWidth;

    componentDidMount() {
        window.addEventListener('scroll', this.stickyPositionEvent);
        window.addEventListener('resize', this.stickyPositionEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.stickyPositionEvent);
        window.removeEventListener('resize', this.stickyPositionEvent);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.parentElemWidth) {
            this.setParentElemWidth();
        }
        if (prevProps.menuToggleState !== this.props.menuToggleState) {
            setTimeout(() => {
                this.setParentElemWidth();
                this.stickyPositionEvent();
            }, 400); // Wait until menu transition is completed to get the correct width
        }
    }

    setParentElemWidth = () => {
        const {parentRef} = this.props;
        const parentContainerElem = parentRef.current;
        if (parentContainerElem) {
            const parentPaddingLeft = window.getComputedStyle(parentContainerElem, null).getPropertyValue('padding-left');
            const parentPaddingRight = window.getComputedStyle(parentContainerElem, null).getPropertyValue('padding-right');
            this.parentElemWidth = (parentContainerElem.offsetWidth || parentContainerElem.clientWidth) - Number(parentPaddingLeft.replace('px', '')) - Number(parentPaddingRight.replace('px', ''));
        }
    }

    stickyPositionEvent = () => {
        const {parentRef, offset = 64} = this.props;
        const treeContainerElem = this.treeContainerRef.current;
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if(!treeContainerElem?.style) {
            return;
        }

        this.setParentElemWidth();

        const top = parentRef.current ? (window.scrollY || document.body.scrollTop) + parentRef.current?.getBoundingClientRect().top - offset : 85;
        if (scrollTop > top) {
            treeContainerElem.style.position = 'fixed';
            treeContainerElem.style.width = `${this.parentElemWidth}px`;
            treeContainerElem.style.top = '60px';
        } else {
            treeContainerElem.style.position = 'relative';
            treeContainerElem.style.width = '100%';
            treeContainerElem.style.top = 0;
        }
    }

    handleToggle = (event, nodeIds) => {
        const {onPutNodeProperty, executionId} = this.props;
        onPutNodeProperty({
            id: executionId,
            treeViewExpandedState: nodeIds,
        })
    };

    handleSelect = (event, nodeIds) => {
        const {onPutNodeProperty, executionId} = this.props;
        onPutNodeProperty({
            id: executionId,
            treViewSelectedState: nodeIds,
        })
    };


    render() {
        const {classes, steps, executionId, treViewSelectedState, treeViewExpandedState} = this.props;

        return <div className={classes.container} ref={this.treeContainerRef} data-cy-element={"Toc"}>
            <div className={classes.header}>
                {strings.execution.procedureViewType.treeView.navigatorHeading}
            </div>
            <div className={classes.content}>
                <TreeView
                    className={classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    expanded={treeViewExpandedState}
                    selected={treViewSelectedState}
                    onNodeSelect={this.handleSelect}
                    onNodeToggle={this.handleToggle}
                >
                    {
                        steps.map((step, stepInd) => {
                            return <ExecutionTOCItem dataCy={`step-${stepInd}`} item={step} executionId={executionId} key={step.id} closeOnSelect={!step.children?.length}>
                                {
                                    step.children.map((task, taskInd) => {
                                        return <ExecutionTOCItem dataCy={`task-${stepInd}-${taskInd}`} item={task} key={task.id}
                                                                 executionId={executionId} closeOnSelect={true}/>
                                    })
                                }
                            </ExecutionTOCItem>
                        })
                    }
                </TreeView>
            </div>
        </div>
    }
}

const styles = theme => ({
    container: {
        width: '100%',
        background: theme.palette.primary.two.main,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: 4,
        maxHeight: 'calc(100vh - 120px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        /* Track */
        '&::-webkit-scrollbar': {
            width: '12px',
            backgroundColor: '#fff',
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c1c1c1',
            borderRadius: 12,
        },
        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover' : {
            backgroundColor: '#c1c1c1'
        }
    },
    header: {
        width: '100%',
        height: 35,
        color: theme.palette.grey.seven.main,
        padding: '9px 12px',
        fontSize: 12,
        borderBottom: `1px solid ${theme.palette.grey.one.main}`,
        textTransform: 'uppercase',
    },
    content: {
        width: '100%',
        height: 'calc(100% - 30px)',
        padding: 10,
    },
    stepWrapper: {
        borderBottom: `1px solid ${theme.palette.grey.one.main}`,
        padding: '6px 0',
        '&:last-child': {
            borderBottom: 0,
        }
    },
});

ExecutionTOC.propTypes = {
    executionId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    let executionNode = getNodeOrNull(state, ownProps.executionId);
    const troubleshootOn = getNodeOrNull(state, NODE_IDS.UserDevice)?.troubleshootOn === true;
    let stepIds = (executionNode && executionNode.children) || [];
    let stepNodes = getNodesIfPresent(state, stepIds);
    // TODO Introduce tocLevels, for now using toc means 1 level
    let oneStep = stepNodes.filter(a => a.visible).length === 1;
    let oneTaskPerStep = stepNodes.filter(a => a.children.length > 1).length === 0;
    let includeTasks = true;
    let includeSteps = true;
    if (oneStep) {
        includeSteps = false;
        includeTasks = true;
    } else if (oneTaskPerStep) {
        includeSteps = true;
        includeTasks = false;
    }
    // If 1 step, or 1 task per step,
    let steps = stepNodes.filter(d => troubleshootOn || d.visible).flatMap((step, stepIndex) => {
        let stepTotalQuestionCount = 0, stepCompletedQuestionCount = 0;
        let tasks = [];
        if (includeTasks) {
            tasks = getNodesById(state, step.children).filter(d => troubleshootOn || d.visible).map(task => {
                const questions = getNodesById(state, task.children);
                const completedQuestions = questions.filter(question => question.completed);
                const taskTotalQuestionCount = questions.length + (task.completeEnabled ? 1 : 0);
                const taskCompletedQuestionCount = completedQuestions.length + (task.completeEnabled && task.completed ? 1 : 0);

                stepTotalQuestionCount = stepTotalQuestionCount + questions.length + (task.completeEnabled ? 1 : 0);
                stepCompletedQuestionCount = stepCompletedQuestionCount + completedQuestions.length + (task.completeEnabled && task.completed ? 1 : 0);

                return {
                    id: task.id,
                    name: task.name,
                    status: taskCompletedQuestionCount === 0 ? 'notstarted' : taskCompletedQuestionCount !== taskTotalQuestionCount ? 'inprogress' : 'done',
                    warningCount: task.warningCount,
                    level: 'task',
                    visible: task.visible,
                    stepIndex,
                    children: []
                }
            });
        }
        if (!includeSteps) {
            return tasks;
        }
        return [{
            id: step.id,
            name: step.name,
            children: tasks,
            status: stepCompletedQuestionCount === 0 ? 'notstarted' : stepCompletedQuestionCount !== stepTotalQuestionCount ? 'inprogress' : 'done',
            warningCount: step.warningCount,
            level: 'step',
            visible: step.visible,
            stepIndex
        }]
    });

    return {
        steps: steps,
        menuToggleState: state.appReducer.toggleState,
        treViewSelectedState: executionNode.treViewSelectedState,
        treeViewExpandedState: executionNode.treeViewExpandedState,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionTOC));