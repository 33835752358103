import React, { useCallback } from "react";
import { useActiveChildRuleByActionOrNull, useCallbackPatchNode, useGetOrCreateNode, useNodeOrNull, useRuleByActionOrNull } from "../../../hooks/nodeHooks";
import { NODE_IDS, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE } from "../../../reducers/graphReducer";
import { useActiveExecutionQuestions } from "../../../hooks/executionHooks";
import { strings } from "../../components/SopLocalizedStrings";
import { Panel } from "tbf-react-library";
import WorkItems from "../../workItems/WorkItems";
import { useCallbackCreateRuleCollectionColumn } from "../../../hooks/procedureHooks";

const pageStrings = strings.procedure.settings.collection;

export default function ProcedureSettingsReportDesign({nodeId, viewId, disabled}) {
    const node = useNodeOrNull(nodeId);

    const pivotSettings = useActiveChildRuleByActionOrNull(viewId, RULE_ACTION_TYPE.pivotSettings.id);

    const patch = useCallbackPatchNode();

    const preview = useGetOrCreateNode(`preview-procedure-${node.rootId}`, NODE_TYPE_OPTIONS.ExecutionPreview, {
        procedureId: node.rootId,
    });

    const questionPreview = useActiveExecutionQuestions(preview.executionId).filter(q => q.procedureQuestionId === nodeId)[0];

    const createPivotSettings = useCallbackCreateRuleCollectionColumn(viewId, RULE_ACTION_TYPE.pivotSettings.id);

    const handleSave = useCallback(({pivotSettings: newSettings}) => {
        const json = JSON.stringify(newSettings);
        if (!pivotSettings) {
            createPivotSettings({calculateValueHuman: json, calculateValueQuery: JSON.stringify(json)});
        } else {
            patch({id: pivotSettings.id, calculateValueHuman: json, calculateValueQuery: JSON.stringify(json)});
        }
    }, [patch, pivotSettings, createPivotSettings]);

    if (!questionPreview) return null;

    const selectorId = NODE_IDS.ExecutionListingPage(questionPreview.id);

    return <Panel title={pageStrings.collectionReportDesign} >
        <WorkItems
            nodeId={questionPreview.id}
            selectorId={selectorId}
            disabled={disabled}
            onSave={handleSave}
        />
    </Panel>;
}