import "./trustedTypesPolicy";
import React from 'react';
import ReactDOM from 'react-dom/client';
import registerServiceWorker from './registerServiceWorker';
import "antd/dist/reset.css";
import App from './layouts/App';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // #4595 - <React.StrictMode> caused the add component mount twice which cancelled the add and broke the add dialog
    //<React.StrictMode>
    <App/>
    //</React.StrictMode>
);

registerServiceWorker();
