import React, {Component} from "react";
import {Builder, Query, Utils} from "@react-awesome-query-builder/antd";
import withStyles from '@mui/styles/withStyles';
import {
    getActiveDescendantsAndSelfAsLookup,
    getActiveNodesByType,
    getNodeOrNull,
    getRootNodeOrError
} from "../../../selectors/graphSelectors";
import {connect} from "react-redux";
import {putNodeProperty} from "../../../actions";

import loadConfig from "./ProcedureRuleBuilderConfig";
import '../../../style/query-builder.css'
import cloneDeep from "lodash/cloneDeep";
import {
    DATE_FORMAT_DISPLAY,
    DATETIME_FORMAT_RULE_DISPLAY,
    DIAGNOSTIC_MODES,
    EXECUTION_STATUS_OPTIONS,
    LINK_TYPE_SELECTABLE_OPTIONS,
    NODE_IDS,
    NODE_TYPE_OPTIONS,
    PROCEDURE_TYPE_OPTIONS,
    PROCEDURE_TYPES,
    QUESTION_TYPES,
    SELECT_DATA_SOURCES,
    TIME_FORMAT_RULE_DISPLAY
} from "../../../reducers/graphReducer";
import {Roles} from "../../../roles";
import {extractJsonLogicVars, upgradeJsonLogic} from "../../../factory/sopJsonLogic";
import { executePromise, getApiBaseUrl } from "../../../actions/commonAction";
import keyBy from "lodash/keyBy";
import ProcedureRuleAutocomplete from "./ProcedureRuleAutocomplete";

const stringify = JSON.stringify;
const {jsonLogicFormat, checkTree, loadTree, loadFromJsonLogic, isValidTree} = Utils;
const preStyle = {backgroundColor: "darkgrey", margin: "10px", padding: "10px"};
const preErrorStyle = {backgroundColor: "lightpink", margin: "10px", padding: "10px"};
const emptyInitValue = {"id": Utils.uuid(), "type": "group"};
const loadedConfig = loadConfig();
let debugWithDefault = false;

const getConfig = ({
                       procedureNodes,
                       groups,
                       allProcedures,
                       includeLinks,
                       multiSelectIds,
                       linkedProcedures,
                       treeFields,
                       forFilter
                   }) => {
    let config = cloneDeep(loadedConfig);
    if (debugWithDefault) {
        return config;
    }
    config.fields = {};
    buildProcedureConfig({config, procedureNodes, multiSelectIds,  groups, includeDeleted: true, forFilter});
    merge(config.fields, buildUserRoles());
    merge(config.fields, buildUserGroups(groups));
    merge(config.fields, buildUser());
    if (includeLinks) {
        merge(config.fields, buildProcedureSelects(allProcedures));
    }
    // Decision: Include root id in here so we know which procedure it came from? May help, so lets
    // Decision: Filter and Query can use the same so we can re-use between the 2
    if (Object.keys(linkedProcedures).length > 0) {
        buildProcedureConfig({config, procedureNodes: linkedProcedures, multiSelectIds, fieldIdPrefix: 'execution_link_toNode_', fieldNamePrefix: 'Link -> ', groups, forFilter});
    }

    // build missing node
    merge(config.fields, buildMissingNodeConfig(config, treeFields));

    return config;
};

const buildMissingNodeConfig = (config, treeFields) => {
    const missingFields = {};
    treeFields.forEach(fld => {
        const fieldVar = fld.var;
        if(fieldVar && !config.fields[fieldVar]) {
            missingFields[fieldVar] = {
                label: "INVALID",
                type: fld.type ?? "text",
                valueSources: ["value", "field"],
                defaultOperator: 'is_not_null',
                fieldSettings: {},
            }
        }
    })

    return missingFields;
}

const buildProcedureConfig = ({
                                  config,
                                  procedureNodes,
                                  multiSelectIds,
                                  fieldIdPrefix ='',
                                  fieldNamePrefix = '',
                                  groups,
                                  includeDeleted = false,
                                  forFilter = false
                              }) => {
    const allNodes = Object.values(procedureNodes).filter(node => (!node.deleted && node.type !== NODE_TYPE_OPTIONS.ProcedureRule)).sort((a, b) => a?.number - b?.number);
    allNodes.forEach((node) => {
        let procedureNode = procedureNodes[node.rootId];
        let useIdPrefix = fieldIdPrefix === '' ? '' : fieldIdPrefix + node.rootId + "_";
        let useNamePrefix = fieldNamePrefix === '' ? '' : fieldNamePrefix + procedureNode.name + " -> ";
        let fieldConfigs;
        switch (node.type) {
            case 'ProcedureRoot': {
                fieldConfigs = buildRootConfig(node, groups, includeDeleted);
                break;
            }
            case 'ProcedureStep': {
                fieldConfigs = buildStepConfig(node);
                break;
            }
            case 'ProcedureTask': {
                fieldConfigs = buildTaskConfig(node);
                break;
            }
            case 'ProcedureQuestion': {
                fieldConfigs = buildQuestionConfig(node, procedureNodes, multiSelectIds, node.number, forFilter);
                break;
            }
            default:
                break;
        }
        merge(config.fields, fieldConfigs, useIdPrefix, useNamePrefix);
    });
}

const merge = (obj, values, fieldIdPrefix = '', fieldNamePrefix = '') => {
    if (values) {
        Object.entries(values).forEach(([key, value]) => {
            obj[fieldIdPrefix + key] = {
                ...value,
                label: fieldNamePrefix + value.label,
                tooltip: fieldNamePrefix + value.label
            }
        });
    }
};

const buildStepConfig = (step, fieldIdPrefix = '', fieldNamePrefix = '') => {
    let subFields = {};
    subFields[fieldIdPrefix + step.id + '_completed'] = {
        label: fieldNamePrefix + step.name + ' -> Completed',
        type: 'boolean',
        defaultValue: true,
        mainWidgetProps: {
            labelYes: "Yes",
            labelNo: "No"
        }
    };
    return subFields;
};

const buildTaskConfig = (step) => {
    let subFields = {};
    subFields[step.id + '_completed'] = {
        label: step.name + ' -> Completed',
        type: 'boolean',
        defaultValue: true,
        mainWidgetProps: {
            labelYes: "Yes",
            labelNo: "No"
        }
    };
    return subFields;
};

const buildQuestionConfig = (question, procedureNodes, multiselectIds, nodeIndex, forFilter) => {
    let task = procedureNodes[question.parentId];
    let label = task.name + ` -> Question #${nodeIndex} - ` + question.name;
    let subFields = {};
    let valueConfig = null;
    switch (question.questionType) {
        case QUESTION_TYPES.yesno.id:
            valueConfig = buildQuestionYesNoConfig(question, label);
            break;
        case QUESTION_TYPES.select.id:
            if (question.selectMany === true) {
                valueConfig = buildQuestionMultiSelectConfig(question, label);
                multiselectIds.push(question.id);
            } else {
                valueConfig = buildQuestionSelectConfig(question, label);
            }
            break;
        case QUESTION_TYPES.link.id:
            valueConfig = buildQuestionMultiSelectConfig(question, label);
            multiselectIds.push(question.id);
            break;
        case QUESTION_TYPES.number.id:
            valueConfig = buildQuestionNumberConfig(question, label);
            break;
        case QUESTION_TYPES.text.id:
        case QUESTION_TYPES.phoneNumber.id:
        case QUESTION_TYPES.email.id:
            valueConfig = buildQuestionTextConfig(question, label, forFilter);
            break;
        case QUESTION_TYPES.date.id:
            valueConfig = buildQuestionDateConfig(question, label);
            break;
        case QUESTION_TYPES.time.id:
            valueConfig = buildQuestionTimeConfig(question, label);
            break;
        case QUESTION_TYPES.datetime.id:
            valueConfig = buildQuestionDateTimeConfig(question, label);
            break;
        case 'link':
            return null;
        case QUESTION_TYPES.richText.id:
        case QUESTION_TYPES.geographic.id:
        case QUESTION_TYPES.signature.id:
        case QUESTION_TYPES.message.id:
            valueConfig = buildQuestionObjectConfig(question, label);
            break;
        default:
            return null;
    }
    subFields[question.id + '_finalValue'] = valueConfig;
    return subFields;
};

const buildQuestionYesNoConfig = (question, label) => {
    let options = {};
    question.optionsParsed.forEach(option => options[option.value] = "" + option.label);
    return {
        label: label,
        type: 'select',
        valueSources: ["value", "field"],
        fieldSettings: {
            listValues: options,
        }
    }
};
const LIMIT = 100;
const getSelectFetch = (url) => {
    return async (search, offset, ...rest) => {
        let useUrl = `${getApiBaseUrl()}${url}`;
        if (search) {
            useUrl = useUrl + `&${new URLSearchParams({q: search || ""})}`;
        }
        const pageNumber = (offset / LIMIT) + 1;
        if (pageNumber > 1) {
            useUrl = useUrl + `&${new URLSearchParams({pageNumber})}`;
        }
        const {data} = await executePromise('get', useUrl);
        const options = data.items.map(e => ({value: e.id, title: e.title}));
        return {values: options, hasMore: data.hasNextPage};
    };
}

const getAdditionalSelectConfig = (question) => {
    let additionalProps = {};
    if (question.selectDataSource === SELECT_DATA_SOURCES.executionDynamic.id) {
        additionalProps = {
            fieldSettings: {
                asyncFetch: getSelectFetch(NODE_IDS.ExecutionQuestionSelect(question)),
                useAsyncSearch: true,
                useLoadMore: true,
                factory: ProcedureRuleAutocomplete,
                formatValue: (selected, {asyncListValues = []}) => {
                    const valuesById = keyBy(asyncListValues, (value) => value.key || value.value);
                    if (Array.isArray(selected)) {
                        return selected.map((id) => {
                            const value = valuesById[id];
                            return value?.children || value?.label || value?.title || id
                        });
                    }
                    const value = valuesById[selected];
                    return value?.children || value?.label || value?.title || selected;
                }
            },
        }
    } else {
        let options = {};
        question.optionsParsed.forEach(option => options[option.value] = option.label);
        additionalProps = {
            fieldSettings: {
                listValues: options,
            }
        }
    }
    return additionalProps;
}

const buildQuestionMultiSelectConfig = (question, label) => {
    const questionConfig = getAdditionalSelectConfig(question);
    return {
        label: label,
        type: 'multiselect',
        defaultOperator: "multiselect_any",
        valueSources: ["value", "field"],
        ...questionConfig,
    }
};

const buildQuestionSelectConfig = (question, label) => {
    const questionConfig = getAdditionalSelectConfig(question);
    return {
        label: label,
        type: 'select',
        defaultOperator: 'select_any_in',
        valueSources: ["value", "field"],
        ...questionConfig,
    }
};

const buildQuestionNumberConfig = (question, label) => {
    return {
        label: label,
        type: 'number',
        defaultOperator: 'equal',
        preferWidgets: ["number"],
        valueSources: ["value", "field"],
    }
};

const buildQuestionTextConfig = (question, label, forFilter) => {
    const config = {
        label: label,
        type: 'text',
        defaultOperator: 'equal',
        mainWidgetProps: {
            formatValue: (val) => (JSON.stringify(val)),
            valueLabel: "Name",
            valuePlaceholder: "Enter name",
            valueSources: ["value", "field"],
        }
    };

    if(forFilter) {
        config.operators = ['equal', 'not_equal', 'is_empty', 'is_not_empty'];
    }

    return config;
};

const buildQuestionDateConfig = (question, label) => {
    return {
        label: label,
        type: 'date',
        defaultOperator: 'equal',
        valueSources: ["value", "field"],
        fieldSettings: {
            dateFormat: DATE_FORMAT_DISPLAY
        },
    };
};

const buildQuestionDateTimeConfig = (question, label) => {
    return {
        label: label,
        type: 'datetime',
        defaultOperator: 'equal',
        valueSources: ["value", "field"],
        fieldSettings: {
            dateFormat: DATE_FORMAT_DISPLAY,
            timeFormat: TIME_FORMAT_RULE_DISPLAY,
            valueFormat: DATETIME_FORMAT_RULE_DISPLAY
        },
    };
};

const buildQuestionTimeConfig = (question, label) => {
    return {
        label: label,
        type: 'time',
        valueSources: ['value', 'field'],
        defaultOperator: 'equal',
        fieldSettings: {
            timeFormat: TIME_FORMAT_RULE_DISPLAY
        },
    };
};

const buildQuestionObjectConfig = (question, label) => {
    return {
        label: label,
        type: 'object',
        valueSources: ['field'],
        defaultOperator: 'is_not_null',
        fieldSettings: {},
    };
};

const buildUserRoles = () => {
    let subFields = {};
    let roles = {};
    Object.values(Roles).map(a => roles[a.id] = a.name);
    subFields['user_roles'] = {
        label: 'User -> Roles',
        type: 'multiselect',
        defaultOperator: "multiselect_any",
        fieldSettings: {
            listValues: roles,
        },
        valueSources: ["value"],

    };
    return subFields;
};

// only execution status for now
const buildRootConfig = (procedure, groups, includeDeleted) => {
    let subFields = {};
    let procedureTypeFormatted = PROCEDURE_TYPES[procedure.procedureType].name;
    subFields[procedure.id + '_status'] = {
        label: `${procedureTypeFormatted} -> Status`,
        type: 'select',
        defaultOperator: 'select_equals',
        valueSources: ["value", "field"],
        listValues: EXECUTION_STATUS_OPTIONS,
    };
    if (includeDeleted) {
        subFields[procedure.id + '_deleted'] = {
            label: `${procedureTypeFormatted} -> Deleted`,
            type: 'boolean',
            defaultValue: true,
            valueSources: ["value", "field"],
            mainWidgetProps: {
                labelYes: "Yes",
                labelNo: "No"
            }
        };
    }
    let listValues = {};
    (groups || []).forEach(a => listValues[a.id] = a.name);
    subFields[procedure.id + '_assignedEntities'] = {
        label: `${procedureTypeFormatted} -> Assigned User/Team`,
        type: 'multiselect',
        defaultOperator: 'select_equals',
        valueSources: ["value", "field"],
        fieldSettings: {
            listValues: listValues,
        },
    };
    return subFields;
};

const buildUserGroups = (groups) => {
    let subFields = {};
    let listValues = {};
    groups.forEach(a => listValues[a.id] = a.name);
    subFields['user_groups'] = {
        label: 'User -> Groups',
        type: 'multiselect',
        defaultOperator: "multiselect_any",
        fieldSettings: {
            listValues: listValues,
        },
        valueSources: ["value"],
    };
    return subFields;
};
const buildUser = () => {
    let subFields = {};
    let roles = {};
    Object.values(Roles).map(a => roles[a.id] = a.name);
    subFields['user_executionId'] = {
        label: 'User -> Id',
        type: 'select',
        defaultOperator: "select_any_in",
        fieldSettings: {},
        valueSources: ["value", "field"],
    };
    return subFields;
};
const buildProcedureSelects = (procedures) => {
    let subFields = {};
    subFields['execution_link_linkType'] = {
        label: 'Link -> Link Type',
        type: 'select',
        defaultOperator: 'select_equals',
        fieldSettings: {
            listValues: LINK_TYPE_SELECTABLE_OPTIONS,
        },
    };
    let proceduresNames = {};
    const getTypeName = id => PROCEDURE_TYPES[id] ? PROCEDURE_TYPES[id].name : 'Unknown';
    procedures.forEach(a => proceduresNames[a.id] = getTypeName(a.procedureType) + ' - ' + a.name);
    subFields['execution_link_toNode_procedureId'] = {
        label: 'Link -> Template',
        type: 'select',
        defaultOperator: 'select_equals',
        fieldSettings: {
            listValues: proceduresNames,
        },
    };
    // TODO Use actual type list
    subFields['execution_link_toNode_procedureType'] = {
        label: 'Link -> Template Type',
        type: 'select',
        defaultOperator: 'select_equals',
        fieldSettings: {
            listValues: PROCEDURE_TYPE_OPTIONS,
        },
    };
    let categories = {};
    procedures.forEach(a => categories[a.category] = a.category);
    subFields['execution_link_toNode_category'] = {
        label: 'Link -> Template Category',
        type: 'select',
        defaultOperator: 'select_equals',
        fieldSettings: {
            listValues: categories,
        },
    };

    subFields['execution_link_toNode_status'] = {
        label: 'Link -> Status',
        type: 'select',
        defaultOperator: 'select_equals',
        fieldSettings: {
            listValues: EXECUTION_STATUS_OPTIONS,
        },
    };
    // TODO use keyfields of selected procedurespac
    return subFields;
};

class ProcedureRuleBuilder extends Component {

    constructor(props, context, unmounted) {
        super(props, context);
        this.unmounted = unmounted;
        const {state, nodeId, ruleProperty, question, includeLinks, includeProcedureIds, forFilter} = props;
        let procedure = getRootNodeOrError(state, nodeId);
        let useProcedureIds = includeProcedureIds || [];
        let procedureNodesAsLookup = getActiveDescendantsAndSelfAsLookup(state, procedure.id);
        let linkedProcedures = {};
        for (let useProcedureId of useProcedureIds) {
            linkedProcedures = {
                ...linkedProcedures,
                ...getActiveDescendantsAndSelfAsLookup(state, useProcedureId)
            }
        }
        let allProcedures = getActiveNodesByType(state, 'ProcedureRoot');

        let groups = getActiveNodesByType(state, 'GroupRoot');
        let multiSelectArray = [];

        let treeJSON = question[ruleProperty + 'Query'];
        const treeFields = [];

        // will this have any effect on formula condition??
        if(!!treeJSON) {
            extractJsonLogicVars(JSON.parse(treeJSON), treeFields, true);
        }

        let builderConfig = getConfig({
            ruleProperty,
            procedureNodes: procedureNodesAsLookup,
            groups,
            allProcedures,
            includeLinks,
            multiSelectIds: multiSelectArray,
            linkedProcedures,
            treeFields,
            forFilter
        });

        multiSelectArray.push('user_roles', 'user_groups');

        treeJSON = upgradeJsonLogic(treeJSON, 'some', 'in', multiSelectArray);

        let initTree;
        if (treeJSON !== null && treeJSON !== undefined) {
            initTree = checkTree(loadFromJsonLogic(JSON.parse(treeJSON), builderConfig), builderConfig);
        } else {
            initTree = checkTree(loadTree(emptyInitValue), builderConfig);
        }
        this.state = {
            tree: debugWithDefault ? null : initTree,
            config: builderConfig,
            jsonTree: null,
            multiSelectIds: multiSelectArray
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // Ignore prop changes
        // Accept prop changes
        let updateViaProps = false;
        if (this.props.disabled !== nextProps.disabled) {
            updateViaProps = true;
        }
        return this.state !== nextState || nextContext !== this.context || updateViaProps;
    }

    static getDerivedStateFromProps

    render = () => {
        let {diagnosticsOn, disabled} = this.props;
        let useConfig = this.state.config;
        if (disabled) {
            useConfig = {
                ...useConfig,
                settings: {
                    ...useConfig.settings,
                    immutableGroupsMode: true,
                    immutableFieldsMode: true,
                    immutableOpsMode: true,
                    immutableValuesMode: true,
                    canReorder: false,
                    canRegroup: false,
                }
            }
        }

        return (
            <div>
                <Query
                    {...useConfig}
                    value={this.state.tree}
                    onChange={this.onChange}
                    renderBuilder={this.renderRuleBuilder}
                />
                {diagnosticsOn &&
                    <div className="query-builder-result">
                        {this.renderResult(this.state)}
                    </div>
                }
            </div>
        )
    }

    renderRuleBuilder = (props) => (
        <div className="query-builder-container" style={{padding: "10px"}}>
            <div className="query-builder qb-lite">
                <Builder {...props} />
            </div>
        </div>
    )

    onChange = (immutableTree, config) => {
        let {nodeId, ruleProperty, disabled} = this.props;
        if (disabled) {
            return;
        }
        const {logic, errors} = jsonLogicFormat(immutableTree, config);
        let query = stringify(logic, undefined, 2);
        let human = immutableTree ? Utils.queryString(immutableTree, config, true) : null;

        this.setState({
            query: query,
            human: human,
            tree: immutableTree,
            config: config
        });

        if (errors.length === 0) {
            this.props.onPutNodeProperty({
                id: nodeId,
                // Setting tree to null is important so the newer versions run json logic loop and not the tree loop
                [ruleProperty + 'Tree']: null,
                [ruleProperty + 'Query']: query === undefined ? null : query,
                [ruleProperty + 'Human']: human === undefined ? null : human,
            });
        }
    }

    renderResult = ({tree: immutableTree, config}) => {
        const isValid = isValidTree(immutableTree, config);
        const {logic, data, errors} = jsonLogicFormat(immutableTree, config);
        return (
            <div>
                {isValid ? null : <pre style={preErrorStyle}>{"Tree has errors"}</pre>}
                <hr/>
                <div>
                    <a href="http://jsonlogic.com/play.html" target="_blank"
                       rel="noopener noreferrer">jsonLogicFormat</a>:
                    {errors.length > 0
                        && <pre style={preErrorStyle}>
                {stringify(errors, undefined, 2)}
              </pre>
                    }
                    {!!logic
                        && <pre style={preStyle}>
                {"// Rule"}:<br/>
                            {stringify(logic, undefined, 2)}
                            <br/>
                <hr/>
                            {"// Data"}:<br/>
                            {stringify(data, undefined, 2)}
              </pre>
                    }
                </div>

            </div>
        );
    }
}

const styles = () => ({});
ProcedureRuleBuilder.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let question = getNodeOrNull(state, nodeId);

    return {
        question: question,
        state,
        diagnosticsOn: getNodeOrNull(state, NODE_IDS.UserDevice).diagnosticMode === DIAGNOSTIC_MODES.full.id
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureRuleBuilder));
