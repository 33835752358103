import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import ExecutionShowQuestionAudit from "./ExecutionShowQuestionAudit";
import {PROCEDURE_LINK_STYLE} from "../../reducers/graphReducer";
import {ComponentBase, ErrorBoundary} from "tbf-react-library";
import ExecutionLink from "./question/embed/ExecutionLink";
import ExecutionRulesList from "./troubleshoot/ExecutionRulesList";
import {LegacyTable} from "./ExecutionLegacyTable";
import NodeProcessingWarning from '../graph/NodeProcessingWarning';
import cx from "classnames";
import ExecutionLinkRepeatingSections from './link/ExecutionLinkRepeatingSections';

class ExecutionQuestion extends ComponentBase {

    render() {
        let {
            linkStyle,
            name,
            classes,
            questionId,
            visible,
            disabled,
            troubleshootOn,
            deleted,
            diagnosticsOn,
            executionId,
            question,
            questionIndex,
            inColumn
        } = this.props;
        if (!question) {
            return null;
        }

        if (!question || (!visible && !troubleshootOn)) {
            return null;
        }
        const childCompProps = {...this.props, classes: {}};
        const classNames = cx(classes.gridFlexWrapContainer, {[classes.hidden]: !visible && !deleted, [classes.deleted]: deleted})
        const cyProps = {
            'data-cy-question': name,
            'data-cy-element': 'Question'
        }
        if (inColumn) {
            cyProps['data-cy-attr-row'] = questionIndex;
        }
        switch (linkStyle) {
            case PROCEDURE_LINK_STYLE.report.id:
            case PROCEDURE_LINK_STYLE.table.id:
                return <div className={classNames} {...cyProps}>
                    <div className={`${classes.gridListContainer}`}>
                        <ErrorBoundary>
                            <ExecutionLink questionId={questionId} disabled={disabled}/>
                            {
                                troubleshootOn && <ExecutionRulesList nodeId={questionId} diagnosticsOn={diagnosticsOn}/>
                            }
                        </ErrorBoundary>
                    </div>
                </div>
            case PROCEDURE_LINK_STYLE.legacyProject.id:
                return <LegacyTable queryQuestionId={questionId} name={name} classNames={classNames} classes={classes} executionId={executionId} />
            case PROCEDURE_LINK_STYLE.repeatingSections.id:
                return <div className={classNames} {...cyProps}>
                    <div className={`${classes.gridListContainer}`}>
                        <ErrorBoundary>
                            <ExecutionLinkRepeatingSections questionId={questionId} disabled={disabled} />
                            {
                                troubleshootOn && <ExecutionRulesList nodeId={questionId} diagnosticsOn={diagnosticsOn}/>
                            }
                        </ErrorBoundary>
                    </div>
                </div>;
            default:
                return <div className={classNames} {...cyProps}>
                    <ErrorBoundary>
                        <ExecutionShowQuestionAudit {...childCompProps}/>
                        {
                            troubleshootOn && <>
                                <ExecutionRulesList nodeId={questionId} diagnosticsOn={diagnosticsOn}/>
                                <NodeProcessingWarning nodeId={questionId} />
                            </>
                        }
                    </ErrorBoundary>
                </div>;
        }
    }
}

const styles = (theme) => ({
    gridFlexWrapContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box'
    },
    gridListContainer: {
        overflowY: 'hidden',
        overflowX: 'hidden',
        width: '100%',
        '& .MuiGridList-root': {
            overflow: 'hidden',
            float: 'left'
        },
    },
    hidden: {
        backgroundColor: theme.palette.status.warning.main
    },
    deleted: {
        backgroundColor: theme.palette.deleted.one.main
    }
});
ExecutionQuestion.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    const {questionId} = ownProps;
    let executionQuestionNode = getNodeOrNull(state, questionId);
    let visible = executionQuestionNode?.visible && !executionQuestionNode?.deleted;
    let disabled = ownProps.disabled || executionQuestionNode?.disabled;
    return {
        linkStyle: executionQuestionNode?.linkStyle,
        name: executionQuestionNode?.name,
        disabled: disabled,
        visible: visible,
        executionId: executionQuestionNode?.rootId,
        question: executionQuestionNode,
        deleted: ownProps.deleted || executionQuestionNode?.deleted
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionQuestion));
