import React, {useCallback, useState} from "react";
import {withRouter} from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import ExpandLess from "@mui/icons-material/ExpandLessRounded";
import ExpandMore from "@mui/icons-material/ExpandMoreRounded";
import {TemplateResultCard} from "../components/ExecutionSelectTemplateOption";

const GlobalSearchProcedureList = ({items, history, classes, title}) => {
    const limit = 10;
    const seeAllEnabled = items.length > limit;
    const [seeAll, setSeeAll] = useState(false);

    const getItems = useCallback(() => {
        return seeAllEnabled ? seeAll ? items : items.slice(0, limit) : items;
    }, [items, seeAllEnabled, seeAll]);

    const handleClick = useCallback((item) => {
        history.push(`/list/${item.procedureType}/${item.value}`);
    }, [history]);

    return <div className={classes.container}>
        <div className={classes.title}>{title}</div>
        <div>
            {
                getItems().map((item, index) => <div key={`procedure_${index}`}
                                                     className={classes.customOptionContainer}
                                                     onClick={() => handleClick(item)}>
                    <TemplateResultCard classes={classes} data={item} optionGrouping={true}/>
                </div>)
            }
        </div>
        {
            seeAllEnabled &&
            <div className={classes.footer}>
                <Button
                    className={classes.showMoreButton}
                    startIcon={(seeAll ? <ExpandLess/> : <ExpandMore/>)}
                    onClick={() => setSeeAll(!seeAll)}>
                    {seeAll ? 'See fewer' : 'See all'} {title.toLowerCase()}
                </Button>
            </div>
        }
    </div>;
}

const styles = (theme) => ({
    container: {
        width: '100%',
        borderRadius: 4,
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        padding: '12px 15px',
        float: 'left',
        background: theme.palette.primary.two.main,
    },
    title: {
        fontFamily: theme.fonts.primary.bold,
        height: 30,
        padding: '0 5px',
    },
    footer: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '10px 10px 0px 10px',
    },
    showMoreButton: {
        fontSize: '0.75rem',
        textTransform: 'none',
    },
    customOptionContainer: {
        width: '100%',
        padding: '10px 5px',
        float: 'left',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        cursor: 'pointer',
        transition: 'background .3s',
        '&:last-child': {
            borderBottom: 'none',
        },
        '&:hover': {
            background: theme.palette.secondary.one.main,
        }
    },
    customOptionParentName: {
        width: '100%',
        color: theme.palette.grey.five.main,
        fontSize: 12,
        float: 'left',
        paddingLeft: 30,
    },
    grow: {
        flexGrow: 1,
    },
    customOptionTitle: {
        width: '100%',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        '& .optionIcon': {
            width: 25,
            height: 25,
            marginRight: 8,
        },
        '& .typeLink': {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& .procedureName': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            color: theme.palette.primary.three.main,
        },
        '& .dateLabel': {
            position: 'absolute',
            fontSize: 12,
            right: 0,
            color: theme.palette.grey.five.main,
        },
    },
    categoryContainer: {
        marginTop: 4,
        display: 'block',
        float: 'left',
        paddingLeft: 28,
        '& .categoryTag': {
            background: theme.palette.grey.one.main,
            color: theme.palette.grey.seven.main,
            fontSize: 10,
            marginRight: 4,
            padding: '2px 8px',
            borderRadius: 4,
        },
    },
});

export default withStyles(styles)(withRouter(GlobalSearchProcedureList));
