import {
    getActiveChildrenOrError,
    getActiveNodesOrError,
    getActiveOrError,
    getActivesNodesSafe,
    getChildrenOrError,
    getChildrenSafe,
    getDeepClonedNodeOrError,
    getDescendantsAndSelf,
    getGraph,
    getNodeOrError,
    getNodeOrNull,
    getNodeSchemaOrError,
    getNodesIfPresent,
    getNodesOrError
} from "../selectors/graphSelectors";
import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";
import {
    DATE_FORMAT_STORED,
    DATETIME_FORMAT_STORED,
    DATETIME_TIMEZONE_DISPLAY,
    FORMATS,
    LINK_TYPES,
    NODE_IDS,
    NODE_TYPE_OPTIONS,
    QUESTION_TYPES,
    RULE_ACTION_TYPE,
    RULE_ACTION_TYPE_PASTE_MODE,
    TIME_FORMAT_STORED,
    VISIBLE_MODES
} from "../reducers/graphReducer";
import {createChildNode, createNode, generateId} from "./graphFactory";
import {
    doesIntersect,
    EMPTY_ARRAY,
    getJsonDate,
    hasValue,
    inArray,
    mergeUnique,
    stringInsert,
    uniqueArray
} from "../util/util";
import {reportDeveloperWarning} from "tbf-react-library";
import moment from "moment-timezone";
import {jsonLogicApply, text} from "tbf-jsonlogic";
import {
    getActiveExecutionQuestions,
    getExecutionProcedureNodes,
    getExecutionSummaryFullOrNull
} from "../selectors/executionSelectors";
import {
    getActiveProcedureQuestions,
    getAllRuleNodes,
    getChildRules,
    getProcedureName,
    hasProcedurePermission
} from "../selectors/procedureSelectors";
import {evaluateRule, extractJsonLogicVars,} from "./sopJsonLogic";
import {
    getActiveChildRuleByActionOrNull,
    getActiveRulesForNodeByAction,
    getActiveRulesForNodeIfPresent,
    getRulesForNode
} from "../selectors/ruleSelectors";
import {Permissions} from "../permissions";

export const PROCEDURE_EXECUTION_MAP = {
    ProcedureRoot: {
        executionType: 'ExecutionRoot',
        executionLinkBack: 'procedureId'
    },
    ProcedureStep: {
        executionType: 'ExecutionStep',
        executionLinkBack: 'procedureStepId'
    },
    ProcedureTask: {
        executionType: 'ExecutionTask',
        executionLinkBack: 'procedureTaskId'
    },
    ProcedureQuestion: {
        executionType: 'ExecutionQuestion',
        executionLinkBack: 'procedureQuestionId'
    },
    ProcedureRule: {
        executionType: 'ExecutionRule',
        executionLinkBack: 'procedureRuleId'
    },
};
export const EXECUTION_PROCEDURE_MAP = {
    ExecutionRoot: {
        procedureType: 'ProcedureRoot',
        executionLinkBack: 'procedureId'
    },
    ExecutionStep: {
        procedureType: 'ProcedureStep',
        executionLinkBack: 'procedureStepId'
    },
    ExecutionTask: {
        procedureType: 'ProcedureTask',
        executionLinkBack: 'procedureTaskId'
    },
    ExecutionQuestion: {
        procedureType: 'ProcedureQuestion',
        executionLinkBack: 'procedureQuestionId'
    },
    ExecutionRule: {
        procedureType: 'ProcedureRule',
        executionLinkBack: 'procedureRuleId'
    },
};

const synchronousNode = (beforeState, state, procedureNode, procedureParent, parentProperty, executionParentNode, nodes, executionMap) => {

    let executionNode = executionMap[procedureNode.id];
    if (!executionNode) {
        if (procedureNode.deleted || procedureNode.parentDeleted) {
            return null;
        }
        let executionMapItem = PROCEDURE_EXECUTION_MAP[procedureNode.type];
        let executionSchema = getNodeSchemaOrError(state, executionMapItem.executionType);
        let extraAttr = {
            [executionMapItem.executionLinkBack]: procedureNode.id
        };
        executionNode = createChildNode(executionParentNode, executionSchema, extraAttr);
        nodes[executionNode.id] = executionNode;
        executionMap[procedureNode.id] = executionNode;
    } else {
        executionNode = nodes[executionNode.id] || executionNode;
        let procedureNodeBefore = getNodeOrNull(beforeState, procedureNode.id);
        let hasProcedureChanged = procedureNode !== procedureNodeBefore;
        if (procedureNode.type === NODE_TYPE_OPTIONS.ProcedureRule && procedureNode.actionType === RULE_ACTION_TYPE.layoutColumns.id) {
            hasProcedureChanged = procedureNode.nodeIds.length !== executionNode.nodeIds.length;
        }
        if (hasProcedureChanged) {
            executionNode = cloneDeep(executionNode);
            executionNode.denormalised = false;
            nodes[executionNode.id] = executionNode;

            // Move it
            if (procedureNodeBefore && procedureNodeBefore.parentId !== procedureNode.parentId) {
                let executionNodeBefore = getNodeOrNull(beforeState, executionNode.id);
                let executionBeforeParentNode = nodes[executionNodeBefore.parentId] || getNodeOrNull(beforeState, executionNodeBefore.parentId);
                let oldParentClone = cloneDeep(executionBeforeParentNode);
                oldParentClone[parentProperty] = oldParentClone[parentProperty].filter(a => a !== executionNode.id);
                nodes[oldParentClone.id] = oldParentClone;
                executionNode.parentId = executionParentNode.id;
            }

        }
    }

    // Get already cloned version if there is one
    executionNode = nodes[executionNode.id] || executionNode;
    executionParentNode = nodes[executionParentNode.id] || executionParentNode;

    // Make sure its in the right position
    let indexInProcedureParent = procedureParent[parentProperty].indexOf(procedureNode.id);
    let indexInExecutionParent = executionParentNode[parentProperty].indexOf(executionNode.id);
    if (indexInExecutionParent !== indexInProcedureParent) {
        executionParentNode = cloneDeep(executionParentNode);
        if (indexInExecutionParent >= 0) {
            executionParentNode[parentProperty].splice(indexInExecutionParent, 1);
        }
        executionParentNode[parentProperty].splice(indexInProcedureParent, 0, executionNode.id);
        nodes[executionParentNode.id] = executionParentNode;
    }
    return executionNode;
};

export const synchronousExecution = (beforeState, state, procedure, execution) => {

    let nodes = {};
    let executionMap = keyBy(getExecutionProcedureNodes(state, execution.id), a => a[EXECUTION_PROCEDURE_MAP[a.type].executionLinkBack]);

    // Execution
    synchronousNode(beforeState, state, procedure, procedure, 'children', execution, nodes, executionMap);

    // Step/Task/Question
    let procedureSteps = getChildrenOrError(state, procedure);
    for (let procedureStep of procedureSteps) {
        let executionStep =
            synchronousNode(beforeState, state, procedureStep, procedure, 'children', execution, nodes, executionMap);
        if (executionStep == null) {
            continue;
        }
        let procedureTasks = procedureStep ? getChildrenOrError(state, procedureStep) : [];
        for (let procedureTask of procedureTasks) {
            let executionTask =
                synchronousNode(beforeState, state, procedureTask, procedureStep, 'children', executionStep, nodes, executionMap);
            if (executionTask == null) {
                continue;
            }
            let procedureQuestions = procedureTask ? getChildrenOrError(state, procedureTask) : [];
            for (let procedureQuestion of procedureQuestions) {
                synchronousNode(beforeState, state, procedureQuestion, procedureTask, 'children', executionTask, nodes, executionMap);
            }
        }
    }

    // Rules
    {
        let procedureRules = getNodesOrError(state, procedure.rules, procedure);
        for (let procedureRule of procedureRules.filter(a => a.procedureOnly !== true)) {
            if (procedureRule.actionType === RULE_ACTION_TYPE.grant.id) {
                console.warn('Why am I here?')
                continue;
            }
            synchronousNode(beforeState, state, procedureRule, procedure, 'rules', execution, nodes, executionMap);
        }
    }

    // All done
    if (Object.keys(nodes).length > 0) {
        // If visibleRule or key fields has changed we need to re-compute the ExecutionRoot
        let executionUpdate = nodes[execution.id] || cloneDeep(execution);
        executionUpdate.denormalised = false;
        nodes[execution.id] = executionUpdate;
    }
    return nodes;
};

export const createExecutions = (state, createExecutionNode) => {

    const schemas = state.schema;
    const createExecutionQuestions = getChildrenSafe(state, createExecutionNode);
    const answersByQuestionId = keyBy(createExecutionQuestions, q => q.procedureQuestionId);
    const procedure = getNodeOrError(state, createExecutionNode.procedureId);
    if (!procedure || !procedure.children || procedure.children.length === 0) {
        throw Error(`Procedure ${procedure.name} ${procedure.id} is not fully loaded.`);
    }
    const completeNodeId = createExecutionNode.signOffNodeId;
    const newNodes = [];
    const user = getNodeOrError(state, NODE_IDS.User);
    for (let i = 0; i < createExecutionNode.procedureCount; i++) {
        let rootAttr = {
            projectId: createExecutionNode.projectId,
            procedureId: procedure.id,
            id: createExecutionNode.executionId || null,
            loadedFull: true,
            source: createExecutionNode.source,
            scopes: createExecutionNode.scopes,
        };
        let executionRoot = createNode(schemas['ExecutionRoot'], rootAttr);
        let procedureSteps = getActiveChildrenOrError(state, procedure);
        for (let procedureStep of procedureSteps) {
            let executionStep = createChildNode(executionRoot, schemas['ExecutionStep'], {
                procedureStepId: procedureStep.id,
            });
            newNodes.push(executionStep);
            let procedureTasks = getActiveChildrenOrError(state, procedureStep);
            for (let procedureTask of procedureTasks) {
                let executionTask = createChildNode(executionStep, schemas['ExecutionTask'], {
                    procedureTaskId: procedureTask.id
                });
                newNodes.push(executionTask);
                let procedureQuestions = getActiveChildrenOrError(state, procedureTask);
                for (let procedureQuestion of procedureQuestions) {
                    let extraAttr = {
                        procedureQuestionId: procedureQuestion.id
                    };
                    let createQuestion = answersByQuestionId[procedureQuestion.id];
                    if (createQuestion) {
                        if (createQuestion.toValueEnabled && createQuestion.fromValue != null) {
                            extraAttr.initialValue = createQuestion.fromValue + i;
                        } else {
                            extraAttr.initialValue = createQuestion.fromValue;
                        }
                        extraAttr.initialValueDateTime = getJsonDate();
                        extraAttr.initialValueByUser = user.auditName;
                    }
                    let executionQuestion = createChildNode(executionTask, schemas['ExecutionQuestion'], extraAttr);
                    newNodes.push(executionQuestion);
                    executionTask.children.push(executionQuestion.id);
                }
                if (createExecutionNode.completeFlag && procedureTask.id === completeNodeId) {
                    executionTask.userCompleted = {
                        completed: true,
                        completedDate: getJsonDate(),
                        completedUserEmail: user.email,
                        completedUserName: user.name,
                    };
                }
                executionStep.children.push(executionTask.id);
            }
            if (createExecutionNode.completeFlag && procedureStep.id === completeNodeId) {
                executionStep.userCompleted = {
                    completed: true,
                    completedDate: getJsonDate(),
                    completedUserEmail: user.email,
                    completedUserName: user.name,
                };
            }
            executionRoot.children.push(executionStep.id);
        }
        let procedureRules = getActiveOrError(state, procedure, 'rules');
        for (let procedureRule of procedureRules.filter(a => a.procedureOnly !== true)) {
            let executionRule = createChildNode(executionRoot, schemas['ExecutionRule'], {
                procedureRuleId: procedureRule.id
            });
            newNodes.push(executionRule);
            executionRoot.rules.push(executionRule.id);
        }
        newNodes.push(executionRoot);
        if (procedure._metadata) {
            // Copy over metadata when new so that execution does not need to go back to procedure to know if has create
            const permissions = []
            const appliesToRoot = [executionRoot.id]
            for (let perm of procedure._metadata.permissions) {
                // TODO Any we should leave as applying to procedure?
                let newPerm = {permission: perm.permission, nodes: appliesToRoot}
                permissions.push(newPerm)
            }
            executionRoot._metadata = {permissions: permissions}
        }
    }
    return newNodes;
};

export const linkExecutions = (state, from, to, linkType, link1Extra, link2Extra, ruleId, updates) => {

    if (!updates) {
        throw Error("updates is required");
    }
    let toLoaded = typeof (to) === 'object';
    if ((toLoaded && !to.rootId) || !to) {
        throw Error('to is required')
    }
    let linkSchema = getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionLink);
    let addLink = (execution, linkAttributes, includeRule) => {
        if (execution.loadedFull !== true || !hasValue(execution.children)) {
            // If execution is only summary we cannot add the link as we don't know what links are already on it
            // And we will lose the link id once it is loaded in full
            // As links are replicated on the API this should not present an issue
            // Secondly if loaded later a dependency will mean this rule re-runs.
            return;
        }
        let existingLinks = getNodesIfPresent(state, execution.links);
        let existingLink = existingLinks.find(a => a.toNodeId === linkAttributes.toNodeId && a.linkType === linkAttributes.linkType);
        if (existingLink) {
            let toNode = getNodeOrNull(state, existingLink.toNodeId);
            if (existingLink.deleted && !toNode?.loadedFull) {
                execution.newLinksForLoad = mergeUnique(execution.newLinksForLoad, [existingLink.id]);
            }
            existingLink = cloneDeep(existingLink);
            existingLink.deleted = false;
            existingLink.draft = linkAttributes.draft;
            if (existingLink.draft !== true) {
                delete existingLink.draft;
            }
            if (ruleId && includeRule) {
                existingLink.ruleIds = mergeUnique(existingLink.ruleIds, ruleId);
                existingLink.activeRuleIds = mergeUnique(existingLink.activeRuleIds, ruleId);
            }
            updates[existingLink.id] = existingLink;
        } else {
            if (ruleId && includeRule) {
                linkAttributes.ruleIds = [ruleId];
                linkAttributes.activeRuleIds = [ruleId];
            }
            let newLink = createChildNode(execution, linkSchema, linkAttributes);
            updates[newLink.id] = newLink;
            execution.links = [...execution.links, newLink.id];
            let toNode = getNodeOrNull(state, newLink.toNodeId);
            if (!toNode?.loadedFull) {
                execution.newLinksForLoad = mergeUnique(execution.newLinksForLoad, [newLink.id]);
            }
            updates[execution.id] = execution;
        }
    }

    // #1 Links: to -> from
    let linktypeObj = LINK_TYPES[linkType];
    let reverseLink = linktypeObj.reverseId;
    if (toLoaded && to.loadedFull && (from.rootId !== to.rootId || linkType !== reverseLink)) {
        let lineOneAttr = {
            linkType: reverseLink,
            toNodeId: from.rootId,
            toNodeTitle: from.title,
            toNodeKey: from.key,
            toNodeProcedureId: from?.procedureId,
            toNodeName: from?.name,
            ...link2Extra
        };
        addLink(to, lineOneAttr, false);
    }

    // #2 Links: from -> to
    {
        let lineTwoAttr = {
            linkType: linkType,
            toNodeId: toLoaded ? to.rootId : to,
            toNodeTitle: toLoaded ? to.title : 'Not loaded',
            toNodeKey: toLoaded ? to.key : null,
            toNodeProcedureId: toLoaded ? to.procedureId : null,
            toNodeName: toLoaded ? to.name : null,
            ...link1Extra
        };
        addLink(from, lineTwoAttr, true);
    }

    // #3 Special Case Parent/Child
    // While .parent is also set on the ExecutionLink code, we need it here to stop
    // A recursion autocreate bomb
    let isParent = linkType === LINK_TYPES.parent.id;
    let isChild = linkType === LINK_TYPES.child.id;
    if (isParent || isChild) {
        let parent = isParent ? to : from;
        let child = isParent ? from : to;
        if (parent?.procedureId && child?.procedureId) {
            let parentDto = {
                id: parent.rootId,
                procedureId: parent.procedureId,
                title: parent.title,
                key: parent.key,
                deleted: parent.deleted,
                procedureName: parent.name,
                procedureType: parent.procedureType
            };
            child.parents = [...(parent.parents || []), parentDto];
        }

    }
    addDependency(updates, {from: from, to: to, properties: ['loadedFull']})
    return updates;
}

export const NODE_RULE_MATCH = (ruleType) => {
    switch (ruleType) {
        case 'jsonLogic':
            return new RegExp(/("var": ?")([a-zA-Z0-9-]+)_([a-zA-Z_]+)/g);
        default:
            return new RegExp(/{{(.+?)::.*?}}/g);
    }
};


function buildProcedureToExecutionMap(execution, descendants) {
    let procedureToExecution = {};
    procedureToExecution['execution'] = execution.id;
    for (let descendant of descendants) {
        let propertyToProcedure = EXECUTION_PROCEDURE_MAP[descendant.type].executionLinkBack;
        let procedureNodeId = descendant[propertyToProcedure];
        procedureToExecution[procedureNodeId] = descendant.id;
    }
    return procedureToExecution;
}

export const rewriteRuleNodeIds = (state, execution, nodeIds, descendants, procedureToExecution) => {
    let missing = nodeIds.filter(a => procedureToExecution[a] === undefined);
    if (missing.length > 0 && !execution.preview) {
        reportDeveloperWarning(`Could not rewrite node ids: ${missing} for execution [${execution.id}] [${execution.key}] preview [${execution.preview}] for template [${execution.procedureId}] [${execution.name}]`)
    }
    return nodeIds.filter(a => procedureToExecution[a]).map(a => procedureToExecution[a]);
}

/**
 * Re-Write all rules on a node.
 * This will re-write rules to use the execution node id's instead of the procedure's
 *
 * I use to do this for all nodes at once but to better support
 * live preview I now do this as part of each nodes processing.
 *
 * Rule rewrite examples are:
 * 1. 'ProcedureQuestion1_completed === true' => 'ExecutionQuestion1_completed === true'
 * 2.
 *
 * Live Preview: If a rule changes we need to remove the dependency from the old dependent and add it to the new.
 * As such this will re-write all rules on the execution when any rule changes.
 *
 * @param beforeState state before this dispatch
 * @param state current state
 * @param execution execution to re-write
 */
export const rewriteRules = (beforeState, state, execution) => {

    // #1 Build a map from procedure node id to execution node id
    let descendants = getExecutionProcedureNodes(state, execution.id);
    let procedureToExecution = buildProcedureToExecutionMap(execution, descendants);
    procedureToExecution['user'] = 'user';

    // #2 Reset all invalidRuleIds
    let updatedNodes = {
        [execution.id]: execution
    };
    let clearFieldIfPresent = ['invalidRuleIds', 'calculateRuleIds'];
    for (let descendant of descendants) {
        for (let clearField of clearFieldIfPresent) {
            if (descendant[clearField] && descendant[clearField].length > 0) {
                let cloned = updatedNodes[descendant.id] || getDeepClonedNodeOrError(state, descendant);
                cloned[clearField] = [];
                updatedNodes[cloned.id] = cloned;
            }
        }
    }

    // #3 Find all rules
    let rules = [];
    let ruleProperties = ['visibleRule', 'linkRule', 'condition', 'calculateValue'];
    for (let descendant of descendants) {
        let propertyToProcedure = EXECUTION_PROCEDURE_MAP[descendant.type].executionLinkBack;
        let procedureNode = getNodeOrError(state, descendant[propertyToProcedure]);
        for (let ruleProperty of ruleProperties) {
            let procedureQuery = procedureNode[ruleProperty + 'Query'];
            let executionQuery = descendant[ruleProperty + 'Query'];
            if (procedureQuery || executionQuery) {
                rules.push({
                    node: descendant,
                    property: ruleProperty + 'Query',
                    query: procedureQuery,
                    human: procedureNode[ruleProperty + 'Human'],
                    ruleType: 'jsonLogic',
                    rewriteIds: descendant.actionType !== RULE_ACTION_TYPE.collectionColumnSource.id && descendant.actionType !== RULE_ACTION_TYPE.collectionOrder.id
                });
            }
        }
    }
    if (execution.titleTemplate) {
        rules.push({
            node: execution,
            property: 'titleTemplate',
            query: execution.titleTemplate,
            ruleType: 'template'
        });
    }

    // #4 Re-write the rules
    // Format is <node id>_<node property>
    for (let rule of rules) {
        let {property, query, node, ruleType} = rule;
        let matches;
        let updatedRule = query;
        if (updatedNodes[node.id]) {
            node = updatedNodes[node.id];
        } else {
            node = cloneDeep(node);
        }
        if (updatedRule && rule.rewriteIds !== false) {
            let pattern = NODE_RULE_MATCH(ruleType);
            do {
                matches = pattern.exec(query);
                if (matches) {
                    let matchedNodeId = matches[ruleType === "jsonLogic" ? 2 : 1];
                    // Convert from procedure question id to execution id
                    let replaceWithId = procedureToExecution[matchedNodeId];
                    if (!replaceWithId) {
                        if (!execution.preview) {
                            reportDeveloperWarning(`Warning processing worq item [${execution.id}] node [${node.id}] of template [${execution.procedureId}] [${execution.name}] preview [${execution.preview}] draft [${execution.draft}] node procedure [${node.procedureId}]. Rewriting JsonLogic [${query}] on execution node [${node.id}] [${node.type}] [${property}] failed. Cannot find node on execution with procedure id [${matchedNodeId}]. This rule will not work as intended.`);
                        }
                        continue;
                    }
                    // We want this to match "var":"XXX and template strings {{XXX but not execution_link_toNode_XXX
                    updatedRule = updatedRule
                        .replace(new RegExp('"' + matchedNodeId, 'g'), '"' + replaceWithId)
                        .replace(new RegExp('{{' + matchedNodeId, 'g'), '{{' + replaceWithId);
                    let otherNode = updatedNodes[replaceWithId] || getDeepClonedNodeOrError(state, replaceWithId);
                    if (!otherNode) {
                        if (!execution.preview) {
                            reportDeveloperWarning(`Warning processing worq item [${execution.id}] node [${node.id}] of template [${execution.procedureId}] [${execution.name}] preview [${execution.preview}] draft [${execution.draft}] node procedure [${node.procedureId}]. Rewriting JsonLogic [${query}] on execution node [${node.id}] [${node.type}] [${property}] failed. Cannot find node on execution with id [${replaceWithId}]. This rule will not work as intended.`);
                        }
                        continue;
                    }
                    if (otherNode.id === NODE_IDS.User) {
                        // If user do not set as assuming user cannot change state without login/logout...
                        // Should re-consider this in future
                    }
                }
            } while (matches);
        }
        node[property] = updatedRule;
        updatedNodes[node.id] = node;
    }

    // #5 Link ExecutionRule to related rules
    for (let ruleId of execution.rules || []) {
        let ruleNode = updatedNodes[ruleId] || getDeepClonedNodeOrError(state, ruleId);
        let propertyToProcedure = EXECUTION_PROCEDURE_MAP[ruleNode.type].executionLinkBack;
        let procedureNode = getNodeOrError(state, ruleNode[propertyToProcedure]);
        ruleNode.nodeIds = rewriteRuleNodeIds(state, execution, procedureNode.nodeIds, descendants, procedureToExecution);
        updatedNodes[ruleNode.id] = ruleNode;
        for (let targetNodeId of ruleNode.nodeIds) {
            let cloned = updatedNodes[targetNodeId] || getDeepClonedNodeOrError(state, targetNodeId);
            if (!cloned) {
                throw Error("Where is my clone at? " + targetNodeId);
            }
            if (procedureNode.invalidInputOn) {
                cloned.invalidRuleIds = mergeUnique(cloned.invalidRuleIds, [ruleNode.id]);
            }
            if (procedureNode.calculateValueOn && cloned.type === NODE_TYPE_OPTIONS.ExecutionQuestion) {
                cloned.calculateRuleIds = mergeUnique(cloned.calculateRuleIds, [ruleNode.id]);
            }
            cloned.ruleIds = mergeUnique(cloned.ruleIds, [ruleNode.id]);
            updatedNodes[cloned.id] = cloned;
        }
    }

    // #6 Add empty rules for rest so they know they have been considered
    for (let node of descendants.filter(a => a.ruleIds == null)) {
        let cloned = updatedNodes[node.id] || {...node};
        cloned.ruleIds = null;
        updatedNodes[cloned.id] = cloned;
    }
    return updatedNodes;
};

export const formatValue = (executionQuestion, value) => {
    if (value == null) {
        return null;
    }
    let formatted = value.toString();
    switch (executionQuestion.questionType) {
        case QUESTION_TYPES.yesno.id:
        case QUESTION_TYPES.select.id: {
            let values = Array.isArray(value) ? value : [value];
            let options = {};
            (executionQuestion.optionsParsed || []).forEach(a => options[a.value] = a.label);
            formatted = values.map(item => options[item] || item).join(', ');
            break;
        }
        case QUESTION_TYPES.number.id: {
            if (executionQuestion.formatDisplay == null) {
                formatted = value.toString();
            } else if(executionQuestion.format === FORMATS.custom.id){
                formatted = text(value, executionQuestion.formatDisplay)
            } else {
                const formatDisplay = executionQuestion.formatDisplay;
                let isPrefix = executionQuestion.format === FORMATS.dollar2.id;
                let prefix = isPrefix ? formatDisplay : '';
                let postFix = isPrefix || !formatDisplay ? '' : ' ' + formatDisplay;
                formatted = prefix + value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }) + postFix;
            }
            break;
        }
        case QUESTION_TYPES.date.id: {
            formatted = moment(value, DATE_FORMAT_STORED).format(executionQuestion.formatDisplay);
            break;
        }
        case QUESTION_TYPES.datetime.id: {
            //.tz(DATETIME_TIMEZONE_DISPLAY)
            formatted = moment(value, DATETIME_FORMAT_STORED).tz(DATETIME_TIMEZONE_DISPLAY).format(executionQuestion.formatDisplay);
            break;
        }
        case QUESTION_TYPES.time.id: {
            formatted = moment(value, TIME_FORMAT_STORED).format(executionQuestion.formatDisplay);
            break;
        }
        case QUESTION_TYPES.geographic.id: {
            formatted = (value.properties && value.properties.name) ||
                (value.geometry && value.geometry.coordinates && value.geometry.coordinates.slice(0, 2).reverse().join(', '));
            break;
        }
        case QUESTION_TYPES.phoneNumber.id: {
            if (value.startsWith("+614")) {
                let mobilePart = value.slice(3);
                formatted = "0" + mobilePart;
                if (formatted.length > 4)
                {
                    formatted = stringInsert(formatted, 4, " ");
                }
                if (formatted.length > 8) {
                    formatted = stringInsert(formatted, 8, " ");
                }
            } else if (value.startsWith("+61")) {
                let landline = value.slice(3);
                formatted = "0" + landline;

                if (formatted.length > 1) {
                    formatted = stringInsert(formatted, 0, "(");
                    formatted = stringInsert(formatted, 3, ") ");
                }
                if (formatted.length > 9)
                {
                    formatted = stringInsert(formatted, 9, " ");
                }
                formatted = formatted.trim();
            } else {
                formatted = String(value);
            }
            break;
        }
        case QUESTION_TYPES.signature.id: {
            formatted = value?.svg ? 'Signed' : 'Not signed'
            break;
        }
        case QUESTION_TYPES.richText.id:
        case QUESTION_TYPES.message.id: {
            let content = JSON.parse(value);
            formatted = (content.blocks || []).map(block => (!block.text.trim() && '\n') || block.text).join('\n')
            break;
        }
        case QUESTION_TYPES.link.id: {
            formatted = null;
            break;
        }
        default:
            break;
    }
    return formatted;
}
export const validateVisibleRule = (state, node, rule) => {
    let result = {compileWarnings: [], dependencies: []};
    let pattern = NODE_RULE_MATCH('jsonLogic');
    let matches;
    do {
        matches = pattern.exec(rule);
        if (matches) {
            let matchId = matches[2];
            result.dependencies.push({from: matchId, to: node, properties: ['deleted', 'parentDeleted']})
            let matchedNode = state.nodes[matchId];
            if (matchedNode === undefined) {
                result.compileWarnings.push(`Visibility Rule references a field [${matchId}] that does not exist`);
            } else if (matchedNode.deleted || matchedNode.parentDeleted) {
                result.compileWarnings.push(`Visibility Rule references the field [${matchedNode.name}] that has been deleted.`);
            }
        }
    } while (matches);
    return result;
};
export const evaluateVisibleRule = (state, node, visibleType, visibleRule, updated) => {
    // Reconsidering this as part of #1633
    let isChild = node.parentId !== node.rootId;
    if (isChild) {
        // If any parent is conditionally hidden then final value should be null
        let parent = getNodeOrError(state, node.parentId);
        let parentIsHidden = false;
        let parentIsConditional = false;
        while (parent) {
            parentIsHidden = parentIsHidden || !parent.visible;
            if (!parent.visible) {
                let rules = getRulesForNode(state, parent.id);
                let visibleRules = rules.filter(a => a.visibleOn && !a.deleted);
                let conditionallyVisible = visibleRules.length > 0 || parent.visibleMode === VISIBLE_MODES.rule.id;
                if (conditionallyVisible) {
                    parentIsConditional = true;
                    break;
                }
            }
            parent = parent.parentId === parent.rootId ? null : getNodeOrError(state, parent.parentId);
        }
        if (parentIsHidden) {
            return {visible: false, conditionallyVisible: parentIsConditional};
        }
    }
    let visible = true;
    let conditionallyVisible = false;
    let visibleRules = [];
    if (node.deleted === true) {
        visible = false;
    } else {
        let rules = getRulesForNode(state, node.id);
        visibleRules = rules.filter(a => a.visibleOn && !a.deleted);
        let visibleRulesOn = visibleRules.filter(a => a.evaluatedValue);
        // Rules override the question settings
        if (visibleRules.length > 0) {
            visible = visibleRulesOn.length > 0;
            conditionallyVisible = true;
        } else {
            switch (visibleType) {
                case 'hidden': {
                    visible = false;
                    break;
                }
                case 'rule': {
                    if (visibleRule) {
                        visible = evaluateRule(state, visibleRule, node, true);
                    }
                    conditionallyVisible = true;
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }
    // Compute dependencies
    if (updated && visibleRule) {
        let dep = extractReferencedNodeDep(node, visibleRule);
        addDependency(updated, dep);
    }
    return {
        visible: visible,
        conditionallyVisible: conditionallyVisible
    };
};
export const addDependency = (update, dependency) => {
    if (!dependency) {
        return;
    }
    let dep = update[NODE_IDS.ReduxDependencies] || {type: NODE_IDS.ReduxDependencies, updates: []};
    let additional = [];
    if (Array.isArray(dependency)) {
        for (let item of dependency.filter(a => a && a.from && a.to)) {
            additional.push(item);
        }
    } else {
        if (dependency && dependency.from && dependency.to) {
            additional.push(dependency);
        }
    }
    dep.updates = [...dep.updates, ...additional];
    update[NODE_IDS.ReduxDependencies] = dep;
}


export const evaluateLinkRule = (state, node, currentNodeIds, updated, useCase) => {
    currentNodeIds = currentNodeIds || [];

    // #1 Decide which rules to run
    let execution = getNodeOrError(state, node.rootId);
    let nodeIsQuestion = node.type === NODE_TYPE_OPTIONS.ExecutionQuestion;
    let matchingRules;
    if (nodeIsQuestion) {
        matchingRules = getActiveNodesOrError(state, node.ruleIds).filter(r => r.linkToQuestionOn);
    } else {
        matchingRules = [node];
    }

    const currentNodeById = {};
    for (let id of currentNodeIds) {
        currentNodeById[id] = true;
    }

    // #2 Run rules
    const nodeIds = [];
    const nodeIdsAdded = {};
    let linkedNodeIds = execution.links || [];
    let links = getActivesNodesSafe(state, linkedNodeIds, execution);
    let allNodesLoaded = true;
    let reduxDependencyUpdates = [];
    for (let rule of matchingRules) {
        const filterRule = getActiveChildRuleByActionOrNull(state, rule.id, RULE_ACTION_TYPE.filter.id);
        const filterJsonLogic = filterRule?.conditionQueryPartial;
        const filterNotComputed = filterRule && filterJsonLogic == null;
        let linkRule = rule.conditionQuery || '';
        if (linkRule !== '') {
            linkRule = linkRule.replace(new RegExp(execution.id + '_link_toNode_procedureId', 'g'), '_link_toNodeProcedureId');
        }
        const linkMatchProcedureIds = rule.linkMatchProcedureIds?.reduce((a, v) => ({...a, [v]: true}), {});
        const linkMatchLinkTypes = rule.linkMatchLinkTypes?.reduce((a, v) => ({...a, [v]: true}), {});
        for (let link of links) {
            if (linkMatchLinkTypes && !linkMatchLinkTypes[link.linkType]) {
                continue;
            }
            if (linkMatchProcedureIds && !linkMatchProcedureIds[link.toNodeProcedureId]) {
                continue;
            }
            const currentlyAdded = currentNodeById[link.toNodeId];
            let added;
            let toNode = getExecutionSummaryFullOrNull(state, link.toNodeId);
            let adjustedRule = linkRule;
            if (adjustedRule !== '') {
                adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link_toNode_procedureId', 'g'), '_link_toNodeProcedureId');
                adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link_toNode', 'g'), link.toNodeId);
                adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link', 'g'), link.id);
            }

            const addAll = adjustedRule === '' && filterRule == null;
            if (toNode == null && addAll) {
                added = true;
            } else if (toNode == null || filterNotComputed) {
                // If to node is not loaded we cannot determine if we should add/remove, so lets place it safe and
                // keep it in list if it is already in the list.
                added = currentlyAdded;
                allNodesLoaded = false;
            } else if (!execution.deleted && toNode.deleted === true) {
                // Lets not add it
                added = false;
                if (currentlyAdded) {
                    console.info(`Removing item ${link.toNodeId} as deleted.`);
                }
            } else if (useCase === QUESTION_TYPES.link.id && ((!execution.draft && toNode.draft) || (!execution.preview && toNode.preview))) {
                // Lets not include draft nodes in non-draft query questions
                added = false;
            } else if (useCase === RULE_ACTION_TYPE.copyToOn.id && ((execution.draft && !toNode.draft) || (execution.preview && !toNode.preview))) {
                // Lets not CopyTo draft to non-draft
                added = false;
            } else {
                let include = !rule.conditionQuery || evaluateRule(state, adjustedRule, node, false);
                if (include && filterJsonLogic != null) {
                    // TODO Check if summary is stale compared to link
                    // TODO Check if summary is missing field as it may be loaded for another reason
                    const data = {
                        execution: toNode,
                        link: link
                    }
                    include = jsonLogicApply(filterJsonLogic, data);
                    if (!include && currentlyAdded) {
                        console.info(`Removing item ${link.toNodeId} as filter does not match.`);
                    }
                }
                added = !!include;
            }
            if (added) {
                if (!nodeIdsAdded[link.toNodeId]) {
                    nodeIdsAdded[link.toNodeId] = true;
                    nodeIds.push(link.toNodeId);
                }
            }

            // If the linked to node changes we need to re-evaluate the rule/question
            reduxDependencyUpdates.push({from: link.id, to: node.id});
            const watchProperties = ['loaded', 'loadedFull', 'draft', 'preview', 'deleted']
            if (filterJsonLogic != null) {
                watchProperties.push('status')
                watchProperties.push('procedureType')
                watchProperties.push('category')
                watchProperties.push('fields')
            }
            reduxDependencyUpdates.push({
                from: link.toNodeId,
                to: node.id,
                properties: watchProperties
            });
            reduxDependencyUpdates.push({
                from: getSummaryId(link.toNodeId),
                to: node.id,
                properties: watchProperties
            });
        }
    }
    // For link added updates
    reduxDependencyUpdates.push({from: node.rootId, to: node.id, properties: ['links', 'draft', 'preview']});
    addDependency(updated, reduxDependencyUpdates);
    return {ids: nodeIds, allNodesLoaded: allNodesLoaded};
};

export const evaluateLinkAddOptions = (state, node) => {
    let execution = getNodeOrError(state, node.rootId);
    let matchingRules = getActiveRulesForNodeIfPresent(state, node.id).filter(r => r.addNewOn);
    let options = [];
    let addOption = (option) => {
        let existing = options.find(a => a.procedureId === option.procedureId);
        if (!existing) {
            options.push(option);
        } else if (!existing.linkTypes.includes(option.linkTypes[0])) {
            existing.linkTypes.push(option.linkTypes[0])
        }
    }
    for (let rule of matchingRules) {
        for (let linkTypeId of (rule.linkMatchLinkTypes || [])) {
            for (let procedureId of (rule.linkMatchProcedureIds || [])) {
                // Should not need to replace execution_, but sometimes it seems rule is not re-written
                let canAdd;
                if (rule.conditionQuery) {
                    let procedure = getNodeOrNull(state, procedureId);
                    if (!procedure) {
                        // Not Loaded yet
                        continue;
                    }
                    let linkRule = rule.conditionQuery || '';
                    let adjustedRule = linkRule.replace(new RegExp('execution_', 'g'), execution.id + '_');
                    adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link_toNode_procedureId', 'g'), "state.currentProcedure.id");
                    adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link_toNode_category', 'g'), "state.currentProcedure.category");
                    adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link_toNode_procedureType', 'g'), "state.currentProcedure.procedureType");
                    adjustedRule = adjustedRule.replace(new RegExp(execution.id + '_link_linkType', 'g'), "state.currentLinkType.id");

                    let modifiedState = {
                        ...state,
                        currentLinkType: LINK_TYPES[linkTypeId],
                        currentProcedure: procedure
                    }
                    canAdd = evaluateRule(modifiedState, adjustedRule, node, false);
                } else {
                    canAdd = true;
                }
                if (canAdd) {
                    const hasAccess = hasProcedurePermission(state, procedureId, Permissions.execution.create)
                    const name = getProcedureName(state, procedureId)
                    addOption(
                        {
                            canCreate: hasAccess,
                            linkTypes: [linkTypeId],
                            procedureId: procedureId,
                            name: name || 'Unknown option'
                        });
                }
            }
        }
    }
    options = options.sort((a, b) => (a.name.localeCompare(b.name)))
    return options;
};

export const textTemplateToHuman = (state, node, template) => {
    if (template === '' || template == null) {
        return {
            template: null,
            templateEditor: null,
            compileErrors: []
        };
    }
    let allQuestions = getDescendantsAndSelf(state, node).filter(a => a.name && !a.deleted && a.type === NODE_TYPE_OPTIONS.ProcedureQuestion);
    let questionIds = {};
    allQuestions.forEach(a => questionIds[a.id] = a.id);
    let compileErrors = [];
    let templateStr = template;
    const pattern = new RegExp(/{{(.+?)::(.+?)}}/g);
    let dependencies = [];
    while (true) {
        let matches = pattern.exec(template);
        if (!matches) {
            break;
        }
        const questionId = matches[1];
        const questionIdValid = questionIds[questionId];
        dependencies.push({from: questionId, to: node});
        if (!questionIdValid) {
            compileErrors.push('Question with id [' + questionId + '] does not exist.');
            continue;
        }
        let question = getNodeOrError(state, questionId);
        if (question.deleted) {
            compileErrors.push('Question [' + question.name + '] is deleted.');
        } else {
            templateStr = templateStr.replace(matches[0], '{{' + question.id + '::' + question.name + '}}');
        }
    }
    return {
        template: templateStr,
        templateEditor: templateStr.replace(/{{[^}]+::/g, '{{'),
        compileErrors: compileErrors,
        dependencies: dependencies
    };
};

export const textTemplateFromHuman = (state, node, template) => {
    const isProcedure = node.type === NODE_TYPE_OPTIONS.ProcedureRoot;

    const allQuestions = isProcedure ? getActiveProcedureQuestions(state, node.id).filter(a => a.name) : getActiveExecutionQuestions(state, node.id).filter(a => a.name);
    let questionNameToId = {};
    allQuestions.forEach(a => questionNameToId[a.name.trim().toLowerCase()] = a.id);
    let compileErrors = [];
    let templateStr = template;
    const pattern = isProcedure ? new RegExp(/{{(.+?)}}/g) : new RegExp(/{{(.+?)::(.*?)}}/g);
    let dependencies = [];
    while (true) {
        let matches = pattern.exec(template);
        if (!matches) {
            break;
        }
        const questionId = isProcedure ? null : matches[1];
        const questionName = isProcedure ? matches[1] : matches[2];

        let useQuestionId = questionId;
        if (isProcedure) {
            // Name may have changed, so re-fetch based on name
            useQuestionId = questionNameToId[questionName.trim().toLocaleLowerCase()]
        }
        // Bug - Execution.TitleTemplate is using ProcedureQuestionId, so lets find the question by label instead
        let refNode = getNodeOrNull(state, useQuestionId)
        if (!isProcedure && refNode?.type !== NODE_TYPE_OPTIONS.ExecutionQuestion) {
            useQuestionId = questionNameToId[questionName.trim().toLocaleLowerCase()]
        }

        dependencies.push({from: useQuestionId, to: node, properties: ['name', 'initialValueFormatted', 'deleted']});
        if (!useQuestionId) {
            compileErrors.push('Question with name [' + questionName + '] does not exist.');
        } else {
            let question = getNodeOrNull(state, useQuestionId);
            if (question) {
                templateStr = templateStr.replace(matches[0], '{{' + question.id + '::' + question.name + '}}');
            } else {
                compileErrors.push('Question with id [' + useQuestionId + '] does not exist.');
            }
        }
    }
    return {
        template: templateStr,
        compileErrors: compileErrors,
        dependencies: dependencies
    };
};

export const textTemplateToFormatted = (state, node, template) => {
    let allQuestions = getActiveExecutionQuestions(state, node.id)
        .filter(a => a.name);
    let questionIdToValue = {};
    allQuestions.forEach(a => questionIdToValue[(a.id)] = a.finalValueFormatted);

    let title = template;
    const pattern = new RegExp(/{{(.+?)::.*?}}/g);
    while (true) {
        let matches = pattern.exec(template);
        if (!matches) {
            break;
        }
        const questionId = matches[1].trim();
        const questionValue = questionIdToValue[questionId] || '';
        title = title.replace(matches[0], questionValue);
    }
    title = title.trim();
    if (title.length > 300) {
        title = title.substring(0, 300);
    }
    return title.trim();
};

export const extractReferencedNodeDep = (node, rule, properties) => {
    let referenceIds = [];
    if (!hasValue(rule)) {
        return referenceIds;
    }
    let matches;
    let pattern = NODE_RULE_MATCH('jsonLogic');
    do {
        matches = pattern.exec(rule);
        if (matches && matches.length >= 4) {
            referenceIds.push({from: matches[2], to: node.id, properties: properties ? properties : [matches[3]]});
        }
    } while (matches);

    return referenceIds;
}

export const convertExecutionQuestionTime = (value) => {
    const hrs = value.getHours();
    return (hrs < 10 ? '0' + hrs : hrs) + ':' + value.getMinutes().toLocaleString('en', {
        minimumIntegerDigits: 2,
        useGrouping: false
    });
}

export const parseOptions = (options, unique = false) => {
    if (!options) {
        return [];
    }
    if (unique) {
        return Object.values(options
            .trim()
            .split('\n')
            .filter(item => item.trim())
            .map(item => item.split("="))
            .reduce((map, items) => {
                map[items[0].trim()] = {
                    value: items[0].trim(),
                    label: (items.length > 1 ? items.slice(1).join('=').trim() : items[0].trim())
                }
                return map;
            }, {}));
    } else {
        return options
            .trim()
            .split('\n')
            .filter(item => item.trim())
            .map(item => item.split("="))
            .map(items => ({
                value: items[0].trim(),
                label: (items.length > 1 ? items.slice(1).join('=').trim() : items[0].trim())
            }));
    }
}

export const combineCompileWarnings = nodes => {
    return nodes
        .filter(a => Array.isArray(a.compileWarnings) && a.compileWarnings.length > 0)
        .flatMap(a => a.name + ' - ' + a.compileWarnings.join(' '));
}
export const isSummaryId = id => id && id.startsWith('ExecutionSummary-');
export const getSummaryId = id => isSummaryId(id) ? id : 'ExecutionSummary-' + id;
export const summaryToFullId = id => isSummaryId(id) ? id.replace('ExecutionSummary-', '') : id;

export const isProcedureSummaryId = id => id.startsWith('ProcedureSummary-');
export const getProcedureSummaryId = id => isProcedureSummaryId(id) ? id : 'ProcedureSummary-' + id;
export const procedureSummaryToFullId = id => isProcedureSummaryId(id) ? id.replace('ProcedureSummary-', '') : id;

export const fullToSummary = execution => {
    let summary = {
        ...execution,
        id: getSummaryId(execution.id),
        rootId: getSummaryId(execution.id),
        loadedFull: false
    };
    delete summary.assignments;
    delete summary.children;
    delete summary.links;
    delete summary.rules;
    delete summary.titleTemplate;
    delete summary.treeViewToggleState;
    delete summary.procedureReleaseVersion;
    return summary;
};

export const copyProcedureNodes = (allNodes, copiedNodeId) => {
    const copiedNode = allNodes.find(d => d.id === copiedNodeId);
    const procedureNode = allNodes.find(d => d.type === NODE_TYPE_OPTIONS.ProcedureRoot);
    return {
        copiedNode: copiedNode,
        allNodes: allNodes,
        rootTitle: procedureNode.name,
    }
}

export const cloneNode = (node, schemas) => {
    const schema = schemas[node.type];
    const attributes = {...node, id: generateId(node), ruleIds: undefined};
    if (node.type !== NODE_TYPE_OPTIONS.ProcedureQuestion) {
        attributes.children = [];
    }
    return createNode(schema, attributes);
}

export const cloneNodeAndChildren = (node, allNodes, schemas) => {
    let clonedNodes = [cloneDeep(cloneNode(node, schemas))];
    let ogNodes = [{id: node.id, clonedNodeId: clonedNodes[0].id, rootId: node.rootId, name: node.name}];
    if (node.children) {
        for (let childNodeId of node.children) {
            const childNode = allNodes.find(d => d.id === childNodeId);
            const clonedChildNode = cloneNodeAndChildren(childNode, allNodes, schemas);
            clonedChildNode.clonedNodes[0].parentId = clonedNodes[0].id;
            clonedNodes[0].children.push(clonedChildNode.clonedNodes[0].id);
            clonedNodes = clonedNodes.concat(clonedChildNode.clonedNodes);
            ogNodes = ogNodes.concat(clonedChildNode.ogNodes);
        }
    }
    return {clonedNodes, ogNodes};
}

export const cloneNodeRules = (ogNodes, allNodes, destinationNodeData) => {
    const isSameProcedure = ogNodes[0].rootId === destinationNodeData.rootId;
    const ruleNodes = getAllRuleNodes(allNodes);
    let nodeRules = cloneDeep(ruleNodes.filter(rule => doesIntersect(rule.nodeIds, ogNodes.map(d => d.id)) && !rule.deleted));
    let ogRuleNodes = [];
    let clonedNodeRules = [];

    const getUpdatedNodeIds = (nodeIds, originalNodes) => {
        let newNodeIds = [];
        (nodeIds || []).forEach(d => {
            const ogNode = originalNodes.find(o => o.id === d);
            if (ogNode) newNodeIds.push(ogNode.clonedNodeId);
        });
        return newNodeIds;
    }

    const getRuleWithUpdateNodeIds = (ruleId, nodeIds) => {
        let newNodeIds = getUpdatedNodeIds(nodeIds, ogNodes);
        return {id: ruleId, nodeIds: [...nodeIds, ...newNodeIds]};
    }

    const getExistingRuleWithUpdatedNodeIds = (existingRuleId, existingRuleNodeIds, nodeIds) => {
        let newNodeIds = getUpdatedNodeIds(nodeIds, ogNodes);
        return {id: existingRuleId, nodeIds: [...existingRuleNodeIds, ...newNodeIds]};
    }

    const updateRuleJsonLogicNodeIds = (rule, originalNodes) => {
        let conditionQuery = false;
        let calculateValueQuery = false;
        originalNodes.forEach(originalNode => {
            const regex = new RegExp(originalNode.id, 'g');
            if (rule.conditionQuery && rule.conditionQuery.indexOf(originalNode.id) !== -1) {
                rule.conditionQuery = rule.conditionQuery.replace(regex, originalNode.clonedNodeId);
                rule.conditionHumanStored = rule.conditionHumanStored ? rule.conditionHumanStored.replace(regex, originalNode.clonedNodeId) : null;
                conditionQuery = true;
            }
           if (rule.calculateValueQuery && rule.calculateValueQuery.indexOf(originalNode.id) !== -1) {
                rule.calculateValueQuery = rule.calculateValueQuery.replace(regex, originalNode.clonedNodeId);
                rule.calculateValueHumanStored = rule.calculateValueHumanStored ? rule.calculateValueHumanStored.replace(regex, originalNode.clonedNodeId) : null;
                calculateValueQuery = true;
            }
           // if calculateValueQuery exist and it does not reference both originalNode.clonedNodeId and originalNode.id
           // then we are using the different originalNodes list for this iteration, since there are 2 source for originalNodes (ogNodes and ogRuleNodes)
           //
           // eg. the calculateValueQuery is referencing a question node ( included in ogNodes ) but for this iteration we are using ogRuleNodes as originalNodes
           // threfore "rule.calculateValueQuery.indexOf(originalNode.id) !== -1" will not be satisfied resulting on conditionQuery to be false
           //
           // let's set the conditionQuery to be true for this scenario
           else if (rule.calculateValueQuery && rule.calculateValueQuery.indexOf(originalNode.clonedNodeId) === -1) {
                calculateValueQuery = true;
            }
        });
        return {conditionQuery, calculateValueQuery};
    }

    const createNewRulesAndChildren = (rule) => {
        const ruleAttr = {
            ...rule,
            id: null,
            nodeIds: getUpdatedNodeIds(rule.nodeIds, ogNodes),
            name: 'New Rule',
            rootId: destinationNodeData.rootId,
            scopes: [destinationNodeData.rootId]
        };
        const parentNewRuleNode = createChildNode(destinationNodeData.procedureNode, destinationNodeData.ruleSchema, ruleAttr);
        ogRuleNodes = ogRuleNodes.concat({
            id: rule.id, clonedNodeId: parentNewRuleNode.id, rootId: rule.rootId, name: rule.name
        });

        let childNewRuleNodes = [];
        const createChildenRules = (parentRule, parentRuleId) => {
            const childRules = getChildRules(ruleNodes, parentRule.id);
            childRules.forEach(childRule => {
                const ruleAttr = {
                    ...childRule,
                    id: null,
                    nodeIds: [parentRuleId],
                    name: 'New Child Rule of Parent Rule: ' + parentRule.name,
                    rootId: destinationNodeData.rootId,
                    scopes: [destinationNodeData.rootId]
                };
                const childNewRuleNode = createChildNode(destinationNodeData.procedureNode, destinationNodeData.ruleSchema, ruleAttr);
                if (childNewRuleNode.conditionQuery || childNewRuleNode.calculateValueQuery) {
                    updateRuleJsonLogicNodeIds(childNewRuleNode, ogNodes);
                }
                childNewRuleNodes.push(childNewRuleNode);
                ogRuleNodes = ogRuleNodes.concat({
                    id: childRule.id, clonedNodeId: childNewRuleNode.id, rootId: childRule.rootId, name: childRule.name
                });

                createChildenRules(childRule, childNewRuleNode.id);
            });
        }

        createChildenRules(rule, parentNewRuleNode.id);
        parentNewRuleNode.ruleIds = getUpdatedNodeIds(parentNewRuleNode.ruleIds, ogRuleNodes);
        return childNewRuleNodes.concat(parentNewRuleNode);
    }

    nodeRules.forEach(rule => {
        let createNew = rule.linkMatchOn || false;
        if (rule.conditionQuery || rule.calculateValueQuery) {
            const {conditionQuery, calculateValueQuery} = updateRuleJsonLogicNodeIds(rule, ogNodes);
            createNew = conditionQuery || calculateValueQuery;
            let conditionQueryResults = [], calculateValueQueryResults = [];
            extractJsonLogicVars(JSON.parse((rule?.conditionQuery || '{}')), conditionQueryResults);
            extractJsonLogicVars(JSON.parse((rule?.calculateValueQuery || '{}')), calculateValueQueryResults);
            // Removing all non-existent node conditions when copying to a different template
            if (!isSameProcedure && !conditionQuery && hasValue(conditionQueryResults)) {
                // remove conditionQuery missing reference node
                // we are replacing it with INVALID field
                // rule.conditionQuery = rule.conditionHumanStored = rule.conditionHuman = null;
                createNew = true;
            }

            const ruleExist = destinationNodeData.ruleNodes.some(e => e.id === rule.id);
            if(isSameProcedure && !conditionQuery && hasValue(conditionQueryResults) && !ruleExist) {
                createNew = true;
            }
            if (!isSameProcedure && !calculateValueQuery && hasValue(calculateValueQueryResults)) {
                rule.calculateValueQuery = rule.calculateValueHumanStored = rule.calculateValueHuman = null;
                createNew = true;
            }
        }
        const childRules = getChildRules(ruleNodes, rule.id);
        const childRulesContainsJsonLogic = childRules.filter(childRule => childRule.conditionQuery || childRule.calculateValueQuery).length > 0;
        const ruleAction = Object.values(RULE_ACTION_TYPE).find(r => r.id === rule.actionType);
        let existing;
        if (!isSameProcedure || childRulesContainsJsonLogic || ruleAction?.pasteMode === RULE_ACTION_TYPE_PASTE_MODE.cloneAlways.id) {
            createNew = true;
        }

        if (ruleAction?.pasteMode === RULE_ACTION_TYPE_PASTE_MODE.reuseAlways.id) {
            if (!isSameProcedure) {
                let uniqueBy = ruleAction.uniqueBy || 'actionType';
                existing = ruleNodes.find(r => r.rootId === destinationNodeData.rootId && rule.actionType === r.actionType && rule[uniqueBy] === r[uniqueBy]);
                createNew = existing === undefined;
            } else {
                createNew = false;
            }
        }

        if (createNew) {
            const newRuleNodes = createNewRulesAndChildren(rule);
            clonedNodeRules = clonedNodeRules.concat(newRuleNodes);
        } else {
            if (existing) {
                const existingRuleNode = getExistingRuleWithUpdatedNodeIds(existing.id, existing.nodeIds, rule.nodeIds);
                clonedNodeRules.push(existingRuleNode);
            } else {
                const ruleNode = getRuleWithUpdateNodeIds(rule.id, rule.nodeIds);
                clonedNodeRules.push(ruleNode);
            }
        }
    });

    clonedNodeRules.forEach(clonedNodeRule => {
        let calculateValueQueryResults = [];
        extractJsonLogicVars(JSON.parse((clonedNodeRule?.calculateValueQuery || '{}')), calculateValueQueryResults);
        if (hasValue(calculateValueQueryResults)) {
            const {calculateValueQuery} = updateRuleJsonLogicNodeIds(clonedNodeRule, ogRuleNodes);
            if (!isSameProcedure && !calculateValueQuery && hasValue(calculateValueQueryResults)) {
                clonedNodeRule.calculateValueQuery = clonedNodeRule.calculateValueHumanStored = clonedNodeRule.calculateValueHuman = null;
            }
        }
    });
    return clonedNodeRules;
}

export const pasteProcedureNodes = (allNodes, copiedNode, schemas, destinationNodeData) => {
    const {clonedNodes, ogNodes} = cloneNodeAndChildren(copiedNode, allNodes, schemas);
    const clonedNodeRules = cloneNodeRules(ogNodes, allNodes, destinationNodeData);
    clonedNodes.map(d => {
        d.rootId = destinationNodeData.rootId;
        d.scopes = [d.rootId];
        return d;
    });
    const clonedNode = clonedNodes[0];
    let {cloneToParentNode, childIndex} = getCloneToParentNodeAndIndex(clonedNode, destinationNodeData);
    clonedNode.parentId = cloneToParentNode.id;
    let updatedChildren = [...cloneToParentNode.children];
    updatedChildren.splice(childIndex, 0, clonedNode.id);
    let patchNodes = clonedNodes.concat(clonedNodeRules);
    const parentNode = {id: cloneToParentNode.id, children: updatedChildren};
    if (parentNode.id === destinationNodeData.procedureNode.id) {
        parentNode.rules = mergeUnique(clonedNodeRules.map(d => d.id),destinationNodeData.procedureNode.rules);
        patchNodes = patchNodes.concat(parentNode);
    } else {
        patchNodes = patchNodes.concat(parentNode);
        let newProcedureRuleIds = destinationNodeData?.procedureNode?.rules || [];
        const clonedRuleIds = clonedNodeRules.map(d => d.id);
        clonedRuleIds.forEach(clonedRuleId => {
            if (!inArray(newProcedureRuleIds, clonedRuleId)) {
                newProcedureRuleIds.push(clonedRuleId);
            }
        });
        patchNodes = patchNodes.concat({
            id: destinationNodeData.procedureNode.id, rules: newProcedureRuleIds,
        });
    }
    return patchNodes;
}

export const getCloneToParentNodeAndIndex = (sourceNode, {node, parentNode, nodeIndex, nodes}) => {
    let cloneToParentNode = null, childIndex = null;
    if (sourceNode.type === node.type) {
        cloneToParentNode = parentNode;
        childIndex = nodeIndex + 1;
    } else if (sourceNode.type === NODE_TYPE_OPTIONS.ProcedureStep) {
        cloneToParentNode = nodes.find(d => d.id === node.rootId);
        if (node.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
            cloneToParentNode = node;
            childIndex = 0;
        } else if (node.type === NODE_TYPE_OPTIONS.ProcedureTask) {
            childIndex = cloneToParentNode.children.indexOf(parentNode.id) + 1;
        } else if (node.type === NODE_TYPE_OPTIONS.ProcedureQuestion) {
            const parentStepNode = nodes.find(d => d.id === parentNode.parentId);
            childIndex = cloneToParentNode.children.indexOf(parentStepNode.id) + 1;
        }
    } else if (sourceNode.type === NODE_TYPE_OPTIONS.ProcedureTask) {
        if (node.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
            const parentStepNodeID = node.children[0];
            if (parentStepNodeID) {
                cloneToParentNode = nodes.find(d => d.id === parentStepNodeID);
                childIndex = 0;
            }
        } else if (node.type === NODE_TYPE_OPTIONS.ProcedureStep) {
            cloneToParentNode = node;
            childIndex = 0;
        } else if (node.type === NODE_TYPE_OPTIONS.ProcedureQuestion) {
            cloneToParentNode = nodes.find(d => d.id === parentNode.parentId);
            childIndex = cloneToParentNode.children.indexOf(parentNode.id) + 1;
        }
    } else if (sourceNode.type === NODE_TYPE_OPTIONS.ProcedureQuestion) {
        if (node.type === NODE_TYPE_OPTIONS.ProcedureRoot) {
            const parentStepNodeID = node.children[0];
            if (parentStepNodeID) {
                const parentStepNode = nodes.find(d => d.id === parentStepNodeID);
                const parentTaskNodeID = parentStepNode.children[0];
                if (parentTaskNodeID) {
                    cloneToParentNode = nodes.find(d => d.id === parentTaskNodeID);
                    childIndex = 0;
                }
            }
        } else if (node.type === NODE_TYPE_OPTIONS.ProcedureStep) {
            const parentTaskNodeID = node.children[0];
            if (parentTaskNodeID) {
                cloneToParentNode = nodes.find(d => d.id === parentTaskNodeID);
                childIndex = 0;
            }
        } else if (node.type === NODE_TYPE_OPTIONS.ProcedureTask) {
            cloneToParentNode = node;
            childIndex = 0;
        }
    }

    return {cloneToParentNode, childIndex};
}

export const nullOrTrue = (value) => {
    return !!value;
}

export const uniqueChildren = (state, beforeNode, afterNode) => {
    if (afterNode.children && beforeNode?.children !== afterNode.children) {
        afterNode.children = uniqueArray(afterNode.children);
        for (let childId of afterNode.children) {
            let node = getNodeOrNull(state, childId)
            if (node && node.parentId !== afterNode.id) {
                throw new Error(`Node [${afterNode.type}] with id [${afterNode.id}] has a child [${childId}] that has a different parent [${node.parentId}].`)
            }
        }
    }
    if (beforeNode?.parentId && afterNode.parentId && afterNode.parentId !== beforeNode.parentId) {
        let afterParent = getNodeOrNull(state, afterNode.parentId);
        if (afterParent && afterParent.children && !afterParent.children.includes(afterNode.id)) {
            throw new Error(`Node [${afterNode.type}] with id [${afterNode.id}] has parent [${afterNode.parentId}] that does not list it has a child.`);
        }
        let beforeParent = getNodeOrNull(state, beforeNode.parentId);
        if (beforeParent && beforeParent.children && beforeParent.children.includes(afterNode.id)) {
            throw new Error(`Node [${afterNode.type}] with id [${afterNode.id}] has has moved to parent [${afterNode.parentId}] but is still listed as a child of [${beforeNode.parentId}].`);
        }
    }
}

export const isDeviceLocationKnown = (location) => {
    return location && location.position && location.position.latitude && location.position.longitude;
}

export const getExecutionPreviewReset = (id) => {
    return {id: id, executionId: null, created: false}
}
export const isSavePendingOnExecution = (state, executionId) => {
    return state.graph.dirtyRootIds[executionId] != null;
};
export const isSaveRunningOnExecution = (state, executionId, nodeId) => {
    const graph = getGraph(state)
    return graph.saveRunning &&
        graph.dirtyNodeIds[nodeId] &&
        graph.savingRootIds[executionId];
};

export const getDependentTemplateIds = (state, executionId, {
    includeCreated = false,
    includeSelf = false,
    forOffline = true
} = {}) => {
    let executionNode = getNodeOrNull(state, executionId);
    if (!executionNode || executionNode?.completeAccess?.disabled) {
        return {procedureIds: EMPTY_ARRAY, dependencyUpdates: EMPTY_ARRAY, preloadProcedureIds: EMPTY_ARRAY};
    }
    const dependencyUpdates = [];
    let rules = getNodesIfPresent(state, executionNode?.rules, executionNode).filter(a => !a.deleted);
    let linkProcedureIds = []
    if (forOffline) {
        linkProcedureIds = rules.filter(a => a.addNewOn).flatMap(a => a.linkMatchProcedureIds || []);
    }
    let individualActionProcedureIds = []
    if (forOffline) {
        individualActionProcedureIds = rules
            .filter(a => a.actionType === RULE_ACTION_TYPE.manuallyAddExecution.id && a.evaluatedValue === true && !executionNode.draft)
            .map((a) => a.createExecutionProcedureId);
    }

    let allPossibleProcedureIdsProcessed = {};
    let loadNowProcedureIds = [];
    let preloadProcedureIds = [];
    let getAllPossibleCreateProcedureIds = id => {
        if (allPossibleProcedureIdsProcessed[id]) {
            return [];
        }
        allPossibleProcedureIdsProcessed[id] = true;
        let root = getNodeOrNull(state, id);
        let rules = (root && root.rules && getNodesIfPresent(state, root.rules).filter(a => !a.deleted)) || [];
        let nextProcedureIds = []
        for (let rule of rules.filter(a => a.createExecutionOn && a.createExecutionProcedureId && (!hasValue(a.createExecutionIds) || includeCreated))) {
            const computeOnServer = getActiveChildRuleByActionOrNull(state, rule.id, RULE_ACTION_TYPE.computeOnServer.id);
            const computeOnClient = getActiveChildRuleByActionOrNull(state, rule.id, RULE_ACTION_TYPE.computeOnClient.id);
            const computeNow = computeOnServer == null || computeOnClient != null;
            if (computeNow) {
                if (executionNode.canComplete && !executionNode.completed) {
                    loadNowProcedureIds.push(rule.createExecutionProcedureId)
                } else {
                    preloadProcedureIds.push(rule.createExecutionProcedureId)
                }
                nextProcedureIds.push(rule.createExecutionProcedureId)
            }
            dependencyUpdates.push({from: rule.id, to: executionId, properties: ['evaluatedValue']});
        }
        nextProcedureIds.flatMap(a => getAllPossibleCreateProcedureIds(a));
    }
    getAllPossibleCreateProcedureIds(executionId);
    linkProcedureIds.flatMap(a => getAllPossibleCreateProcedureIds(a));
    let selfProcedure = []
    if (!isNodeSaved(executionNode) || includeSelf) {
        selfProcedure.push(executionNode.procedureId)
    }
    const templateIds = new Set([...loadNowProcedureIds, ...individualActionProcedureIds, ...linkProcedureIds, ...selfProcedure]);

    const result = {procedureIds: [], dependencyUpdates: dependencyUpdates, preloadProcedureIds: []};
    for (let id of templateIds) {
        dependencyUpdates.push({from: id, to: executionId, properties: ['_metadata']});
        if (hasProcedurePermission(state, id, Permissions.execution.create)) {
            result.procedureIds.push(id)
        }
    }
    for (let id of preloadProcedureIds) {
        dependencyUpdates.push({from: id, to: executionId, properties: ['_metadata']});
        if (hasProcedurePermission(state, id, Permissions.execution.create)) {
            result.preloadProcedureIds.push(id)
        }
    }
    if (templateIds.size > 0 || preloadProcedureIds.size > 0) {
        dependencyUpdates.push({from: NODE_IDS.ClientConfig, to: executionId, properties: ['procedures']});
    }
    return result;
}
/**
 * Has the node saved to the server at all.
 *
 * This does not check if it is currently dirty.
 * @param node
 * @returns {boolean}
 */
export const isNodeSaved = (node) => {
    return !!node.createdDateTime;
}

export const getCustomCompleteLabels = (state, executionId, nodeId) => {
    const completeLabels = getActiveRulesForNodeByAction(state, nodeId, RULE_ACTION_TYPE.completeLabels.id);
    const collectionLabel = completeLabels[0];

    if(!collectionLabel) {
        return;
    }

    const labelRuleIds = collectionLabel.ruleIds;
    const labelRules = getNodesIfPresent(state, labelRuleIds);

    const calculatedLabels = {};
    labelRules
        .filter(e => e.actionType === RULE_ACTION_TYPE.label.id)
        .forEach(rule => {
        calculatedLabels[rule.format] = rule.calculateValue;
    })

    const navigateNextOnComplete = labelRules.find(e => e.actionType === RULE_ACTION_TYPE.navigateNextOnComplete.id && !e.deleted);

    return {
        calculatedLabels,
        collectionLabel,
        navigateNextOnComplete: !!navigateNextOnComplete,
        labelRules: labelRules.filter(e => e.actionType === RULE_ACTION_TYPE.label.id),
    };
}
